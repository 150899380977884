import { Project } from './Project';
import { IBreadcrumbs } from './ibreadcrumbs';

export class Parent {
	id: number;
	parentId: number;
	title: string;
	details: string;
	projectId: number;
	projectName: string;
	childDisplayTypeId: number;
	childDisplayTypeName: string;
	childEntityTypeId: number;
	entityTypeName: string;
	imageId: number;
	imageName: string;
	orderIndex: number;
	lastUpdateBy: string;
	isHidden: boolean;
	isHiddenDeep: boolean;

	constructor(p: Parent) {
		this.id = p.id;
		this.parentId = p.parentId;
		this.title = p.title;
		this.details = p.details;
		this.projectId = p.projectId;
		this.projectName = p.projectName;
		this.childDisplayTypeId = p.childDisplayTypeId;
		this.childDisplayTypeName = p.childDisplayTypeName;
		this.childEntityTypeId = p.childEntityTypeId;
		this.entityTypeName = p.entityTypeName;
		this.imageId = p.imageId;
		this.imageName = p.imageName;
		this.orderIndex = p.orderIndex;
		this.isHidden = p.isHidden;
		this.lastUpdateBy = p.lastUpdateBy;
		this.isHiddenDeep = p.isHiddenDeep;
	}
}

export class ParentPage {
	parent: Parent;
	children: any;
	breadCrumbs: IBreadcrumbs[];
}

export class DashboardResponse {
	project: Project;
	dashboard: Parent[];
}
