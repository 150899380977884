import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasicLeaf } from 'src/app/models/_module';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'pm-basic-view-list',
	templateUrl: './basic-view-list.component.html',
	styleUrls: ['./basic-view-list.component.scss'],
})
export class BasicViewListComponent implements OnInit {
	@Input() item: BasicLeaf;
	@Output() append: EventEmitter<BasicLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Basic';
	eye = faEye;
	eyeSlash = faEyeSlash;

	constructor() { }

	ngOnInit(): void {
	}

	appendChild(newItem: BasicLeaf) {
		this.append.emit(newItem);
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}
}
