<pm-shared-header [title]='title' [header]='"Users"' [resourceUrl]='resourceUrl'></pm-shared-header>
<div class="container">
	<div class="row d-flex justify-space-between pl-0">
		<div class="col-12 col-md-6 pl-0">
			<div class="row">
				<div class="col">
					<b>
						<h4 class="m-0">{{ header }}</h4>
					</b>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<a [routerLink]='["/home"]' class="breadcrums">Dashboard</a>
					<span class="breadcrums">{{ " / " + header }}</span>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end pt-2 pt-md-0 pr-0">
			<pm-users-modal (append)="appendUser($event)" [isEdit]='addUser'></pm-users-modal>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<ul class="list-group">
				<li *ngFor="let user of listOfUsers" class="list-group-item mt-3 border border-dark row">
					<div class="row">
						<div class="col-5"><strong class="mr-1">Email:</strong>{{user.email}}</div>
						<div class="col-4"><strong class="mr-1">Project:</strong>{{user.projectName}}</div>
						<div class="col d-flex justify-content-end" *ngIf="currentUserEmail != user.email">
							<pm-users-modal (append)="appendUser($event)" (delete)="deleteUser($event)" [user]="user" [isEdit]='editUser'></pm-users-modal>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>
