import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { DataService } from './data.service';
import { from } from 'rxjs';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	constructor(private dataService: DataService) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		return from(this.handleAccess(request, next));
	}

	private async handleAccess(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Promise<HttpEvent<any>> {
		const token = this.dataService.token;
		return next.handle(request.clone({
			setHeaders: { Authorization: `Bearer ${token}`},
		})).toPromise();
	}
}
