<div class="card float" style="width: 24rem; height: 26rem;">
	<div class="card-body text-left">
		<div class="container" style="height: 100%;">
			<div class="row">
				<div class="col-12">
					<p><b>{{item.title}}</b></p>
					<p class="text-danger" *ngIf="hasEnded"><b>Event has ended</b></p>
					<p class="card-text" *ngIf="!hasEnded"><b class="mr-2">Start Date:&nbsp;</b>{{dateStart | date: 'M/dd/yy h:mm a'}}</p>
					<p class="card-text" *ngIf="item.endTime != null"><b class="mr-2">End Date:&nbsp;</b>
						{{dateEnd | date: 'M/dd/yy h:mm a'}}
					</p>
					<p class="card-text">
						<b class="mr-2">Description:&nbsp;</b>
						<span *ngIf="item.description">{{item.description.length > 50 ? item.description.substring(0,50)+' ...' : item.description}}</span>
					</p>
					<p class="card-text" *ngIf="!item.isVirtual">
						<b class="mr-2">Location:&nbsp;</b>
						<span>{{item.location}}</span>
					</p>
					<p class="card-text" *ngIf="!item.isVirtual">
						<b class="mr-2">Address:&nbsp;</b>
						{{item.address}}
						<br *ngIf="item.address"/>
						{{item.city}},&nbsp;{{item.state}}&nbsp;{{item.zip}}
					</p>
					<p class="card-text" *ngIf="item.linkUrl != null && item.linkUrl != ''">
						<b class="mr-2">Link URL:&nbsp;</b>
						<span *ngIf="item.linkUrl">{{item.linkUrl.length > 50 ? item.linkUrl.substring(0,50)+' ...' : item.linkUrl}}</span>
					</p>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-6">
					<fa-icon *ngIf="!item.isHidden" class="fa-xs greyIcon" [icon]="eye"></fa-icon>
					<fa-icon *ngIf="item.isHidden" class="fa-xs greyIcon" [icon]="eyeSlash"></fa-icon>
					<span class="iconText pl-1 m-0">{{ item.isHidden? "Hidden" : "Visible" }}</span>
				</div>
				<div class="col-6 d-flex justify-content-end">
					<pm-event-leaf-modal [item]="item" (append)="appendChild($event)" (delete)="deleteChild($event)">
					</pm-event-leaf-modal>
				</div>
			</div>
		</div>
	</div>
</div>
