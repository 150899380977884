<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Forgot Password</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form [formGroup]="passwordForm">
		<div class="form-group" >
			<input id="email" class="form-control " formControlName="email" type="text" placeholder="Enter your email address">
			<small class="text-danger" *ngIf="email.invalid && (email.dirty || saveTry)"> **User Email is invalid </small>
		</div>
	</form>
	</div>
	<div class="modal-footer d-flex justify-content-between">
		<button type="button" class="btn btn-dark"(click)="modal.dismiss()">Cancel</button>
		<button id="forgotButon" class="btn btn-primary" (click)="recoverPassword(email.value)" [disabled]="passwordForm.invalid">Submit</button>
	</div>
</ng-template>
<a (click)="open(content)" style="cursor:pointer">Forgot Password?</a>
