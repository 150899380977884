<ng-template #content let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Folder</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="parentForm">

      <div class="form-group">
        <label for="titleOfParent" class="bold">Title:
          <span class="text-danger">*</span></label>
        <input id="titleOfParent" formControlName="titleOfParent" class="form-control" type="text">
        <small class="text-danger" *ngIf="titleOfParent.invalid && (titleOfParent.dirty || saveTry)"> **Title must be 1
          to {{parentId === 0 ? "16" : "30"}} characters</small>
      </div>

      <div class="form-group">
        <label for="detailsOfParent" class="bold">Details: </label>
        <input id="detailsOfParent" formControlName="detailsOfParent" class="form-control" type="text">
        <small class="text-danger" *ngIf="detailsOfParent.invalid && detailsOfParent.dirty"> **Details is invalid
        </small>
      </div>

      <div class="form-group" *ngIf="parentId==0">
        <label id="labelImageOfParent" for="imageNameOfParent" class="mr-3 bold">Icon:
          <span class="text-danger">*</span></label>
        <input id="imageNameOfParent" formControlName="imageNameOfParent" class="form-control" type="text" readonly
          hidden>

        <div ngbDropdown class="d-inline-block" *ngIf="parentId==0">
          <button class="btn btn-outline-primary" id="iconDropdown" ngbDropdownToggle>{{iconInnerText}}</button>
          <div ngbDropdownMenu aria-labelledby="iconDropdown" style="height:175px;overflow:auto;">
            <div *ngFor="let iconItem of pImages">
              <div class="d-flex justify-content-center" (click)="getTheImg(iconItem.resource)">
                <img class="form-img" ngbDropdownItem src="../../../assets/images/{{iconItem.resource}}.svg" alt="">
              </div>
            </div>
          </div>
          <img *ngIf="showImage" src="../../../assets/images/{{imageNameOfParent.value}}.svg" alt=""
            class="mx-3 border-0 form-img-example">
        </div>
      </div>
      <small *ngIf="imageNameOfParent.value == '' && saveTry" class="text-danger">Image is required</small>

      <div class="form-group">
        <div class="row">
          <div class="col">
            <label for="entityTypeOfParent" class="bold">Display Type:
              <span class="text-danger">*</span></label> <br />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 mb-2">
            <select class="custom-select" formControlName="displayTypeOfParent" (change)="typeChange()">
              <option value="" disabled>Choose Type</option>
              <option value="Grid">Grid</option>
              <option value="List">List</option>
              <option value="Page">Page</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <small class="text-danger" *ngIf="displayTypeOfParent.invalid && saveTry"> **Display type is invalid
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="entityTypeOfParent" class="bold">Content Type:
              <span class="text-danger">*</span></label> <br />
          </div>
        </div>
        <div class="row mb-2" [ngSwitch]="displayTypeOfParent.value">
          <div *ngSwitchCase="'List'" class="col-12">
            <select class="custom-select" formControlName="entityTypeOfParent">
              <option value="" disabled>Choose Type</option>
              <option value="Basic">Basic</option>
              <option value="Contact">Contact</option>
              <option value="Event">Event</option>
              <option value="Parent">Folder</option>
              <option value="Resource">Resource</option>
            </select>
          </div>
          <div *ngSwitchCase="'Page'" class="col-12">
            <select class="custom-select" formControlName="entityTypeOfParent">
              <option value="" disabled>Choose Type</option>
              <option value="Resource">Resource</option>
              <option value="Html">Html</option>
            </select>
          </div>
          <div *ngSwitchCase="'Grid'" class="col-12 mb-2">
            <select class="custom-select" formControlName="entityTypeOfParent">
              <option value="" disabled>Choose Type</option>
              <option value="Contact">Contact</option>
              <option value="Event">Event</option>
              <option value="Parent">Folder</option>
              <option value="Resource">Resource</option>
            </select>
          </div>
          <div *ngSwitchDefault class="col-12">
            <select class="custom-select" formControlName="entityTypeOfParent">
              <option value="" disabled>Choose Type</option>
              <option value="Basic">Basic</option>
              <option value="Contact">Contact</option>
              <option value="Event">Event</option>
              <option value="Parent">Folder</option>
              <option value="Resource">Resource</option>
              <option value="Html">Html</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <small class="text-danger" *ngIf="entityTypeOfParent.invalid && saveTry"> **Content type is invalid </small>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="entityTypeOfParent" class="bold">Folder Status:</label><br />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <select class="custom-select" id="isHidden" formControlName="isHidden">
              <option value="" disabled>Choose Status</option>
              <option [ngValue]="false">Visible</option>
              <option [ngValue]="true">Hidden</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="isEdit && noMove != '1'">
          <div class="col">
            <label for="entityTypeOfParent" class="bold">Folder Location:</label><br />
          </div>
        </div>
        <div class="row" *ngIf="isEdit && noMove != '1'">
          <div class="col-12">
            <select class="custom-select" id="isHidden" formControlName="folderLocation">
              <option *ngFor="let l of list" [ngValue]="l.id">{{l.title}}</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <div>
      <button type="button" class="btn btn-dark" (click)="cancelParent()">Cancel</button>
    </div>
    <div>
      <button *ngIf="!isEdit" id="createButton" class="btn btn-primary" (click)="createParent()">Create</button>
			<button *ngIf="isEdit" id="editButton" class="btn btn-primary" (click)="editParent()">Save</button>&nbsp;
      <button *ngIf="isEdit && noMove != '1'" id="deleteButton" class="btn btn-danger" (click)="deleteParent()">Delete</button>
    </div>
  </div>

</ng-template>

<button *ngIf="isEdit" class="btn penBtn btn-background px-1 py-0" (click)="open(content)">
  <span>
    <span class="sr-only">Edit</span>
    <fa-icon [icon]="pen" class="blueIcon" alt="Edit" aria-hidden="true"></fa-icon>
  </span>
</button>
<button *ngIf="!isEdit" class="btn plusBtn bg-primary px-1 py-0" (click)="open(content)">
  <fa-icon class="fa-xs whiteIcon" [icon]="plus"></fa-icon>
  <span class="whiteIcon pl-1 m-0">Add</span>
</button>
