<div class="container">
	<div class="row">
		<div class="col-12 d-flex justify-content-center my-3">
			<img src="../../../assets/imgs/default-login.svg" height="90px">
		</div>
	</div>
	<div class="row justify-content-center" *ngIf="showSpinner">
		<div class="col-12 d-flex justify-content-center">
			<div class="spinner-border my-3 text-primary" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
	</div>
	<div class="row justify-content-center mx-md-0 mx-3">
		<div class="col-12 col-md-6 d-flex justify-content-center border border-primary rounded-lg py-3">
			<form [formGroup]="signInForm" class="container-fluid">
				<div class="form-row justify-content-center" *ngIf="error != null">
					<p class="text-danger">{{ error.length == null ? "Server Error" : error}}</p>
				</div>
				<div class="form-row">
					<div class="form-group col-12" >
						<input id="email" class="form-control " formControlName="email" type="text" placeholder="Enter your email address">
						<small class="text-danger" *ngIf="email.invalid && email.dirty"> **User Email is invalid </small>
					</div>
					<div class="form-group col-12">
						<input id="password" class="form-control" formControlName="password" type="password" placeholder="Enter your password">
						<small class="text-danger" *ngIf="password.invalid && password.dirty"> **Password is invalid </small>
					</div>
				</div>
				<div class="form-row justify-content-center">
					<div class="d-flex">
						<button type="submit" class="btn btn-primary text-center" (click)="submitSignIn()" [disabled]="signInForm.invalid || showSpinner">LOGIN</button> &nbsp;
					</div>
				</div>
				<div class="form-row justify-content-center mt-3">
					<pm-forgot-password-modal></pm-forgot-password-modal>
				</div>
				<pm-new-password-modal [GuidId]="requestId"></pm-new-password-modal>
			</form>
		</div>
	</div>
</div>
