import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


import { EventLeaf } from 'src/app/models/_module';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'pm-event-leaf-modal',
	templateUrl: './event-leaf-modal.component.html',
	styleUrls: ['./event-leaf-modal.component.scss', '../styles.scss'],
})
export class EventLeafModalComponent implements OnInit {
	eventForm: FormGroup;
	@Input() eTypeId: number;
	@Input() pId: number;
	@Input() item: EventLeaf;
	isEdit: Boolean;
	saveTry = false;

	@Output() append: EventEmitter<EventLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	fileLabels: string[] = [];
	fileUrls: string[] = [];
	pdfFileUrl: string;
	pdfFileLabel: string;
	fileUploadDate: Date;
	pen = faPen;
	plus = faPlus;
	createdDate: Date;
	lastUpdated: Date;

	// retrieve information from the user
	get title() { return this.eventForm.get('title'); }
	get startDate() { return this.eventForm.get('startDate'); }
	get startTime() { return this.eventForm.get('startTime'); }
	get endDate() { return this.eventForm.get('endDate'); }
	get endTime() { return this.eventForm.get('endTime'); }
	get description() { return this.eventForm.get('description'); }
	get location() { return this.eventForm.get('location'); }
	get address() { return this.eventForm.get('address'); }
	get linkUrl() { return this.eventForm.get('linkUrl'); }
	get linkName() { return this.eventForm.get('linkName'); }
	get fileUrl() { return this.eventForm.get('fileUrl'); }
	get fileName() { return this.eventForm.get('fileName'); }
	get host1Name() { return this.eventForm.get('host1Name'); }
	get host1Title() { return this.eventForm.get('host1Title'); }
	get host1ImageUrl() { return this.eventForm.get('host1ImageUrl'); }
	get host2Name() { return this.eventForm.get('host2Name'); }
	get host2Title() { return this.eventForm.get('host2Title'); }
	get host2ImageUrl() { return this.eventForm.get('host2ImageUrl'); }
	get host3Name() { return this.eventForm.get('host3Name'); }
	get host3Title() { return this.eventForm.get('host3Title'); }
	get host3ImageUrl() { return this.eventForm.get('host3ImageUrl'); }
	get isVirtual() { return this.eventForm.get('isVirtual'); }
	get isHidden() { return this.eventForm.get('isHidden'); }
	get city() { return this.eventForm.get('city'); }
	get state() { return this.eventForm.get('state'); }
	get zip() { return this.eventForm.get('zip'); }
	get hasHost1() { return this.eventForm.get('hasHost1'); }
	get hasHost2() { return this.eventForm.get('hasHost2'); }
	get hasHost3() { return this.eventForm.get('hasHost3'); }

	constructor(private modalService: NgbModal,
		public dService: DataService,
		private formbuilder: FormBuilder,
		public router: Router,
		public route: ActivatedRoute) { }

	ngOnInit(): void {
		this.isEdit = this.item != null;
		// Initialize Event Form & validate
		this.eventForm = this.formbuilder.group({
			title: ['', [Validators.required, Validators.maxLength(50)]],
			startDate: [null, [Validators.required]],
			startTime: [null, [Validators.required]],
			endDate: [],
			endTime: [],
			description: [''],
			location: [''],
			address: [''],
			linkUrl: [null],
			linkName: [null],
			fileUrl: [null],
			fileName: [null],
			host1Name: ['', [Validators.maxLength(75)]],
			host1Title: ['', [Validators.maxLength(50)]],
			host1ImageUrl: [''],
			host2Name: ['', [Validators.maxLength(75)]],
			host2Title: ['', [Validators.maxLength(50)]],
			host2ImageUrl: [''],
			host3Name: ['', [Validators.maxLength(75)]],
			host3Title: ['', [Validators.maxLength(50)]],
			host3ImageUrl: [''],
			city: ['', [this.addressValidator('address', 'isVirtual')]],
			state: ['', [this.addressValidator('address', 'isVirtual')]],
			zip: ['', [this.addressValidator('address', 'isVirtual'), Validators.minLength(5)]],
			isVirtual: [false],
			isHidden: [true],
			hasHost1: [false],
			hasHost2: [false],
			hasHost3: [false],
		});
	}

	addressValidator(addressInputName: string, checkboxInputName) {
		let targetControl: FormControl;
		let addressControl: FormControl;
		let checkboxControl: FormControl;
		return (control: FormControl) => {
			if (!control.parent) {
				return null;
			}
			if (!targetControl) {
				targetControl = control;
				addressControl = control.parent.get(addressInputName) as FormControl;
				addressControl.valueChanges.subscribe(() => {
					targetControl.updateValueAndValidity();
				});
				checkboxControl = control.parent.get(checkboxInputName) as FormControl;
				checkboxControl.valueChanges.subscribe(() => {
					targetControl.updateValueAndValidity();
				});
			}
			if (!checkboxControl.value &&
				!(addressControl.value == null || addressControl.value === '')
				&& (targetControl.value == null
					|| targetControl.value === '')
			) {
				return {
					addressNotEmpty: true,
				};
			}
			return null;
		};
	}

	dateString(datetime: Date): string {
		if (datetime == null) { return null; }
		const year = datetime.getFullYear();
		const month = `${(datetime.getMonth() + 1) / 10 < 1 ? '0' : ''}${(datetime.getMonth() + 1)}`;
		const day = `${(datetime.getDate()) / 10 < 1 ? '0' : ''}${(datetime.getDate())}`;
		return `${year}-${month}-${day}`;
	}

	timeString(datetime: Date): string {
		if (datetime == null) { return null; }
		const hour = `${datetime.getHours() / 10 < 1 ? '0' : ''}${datetime.getHours()}`;
		const min = `${(datetime.getMinutes() / 10 < 1) ? '0' : ''}${datetime.getMinutes()}`;
		return `${hour}:${min}`;
	}

	open(content: any) {
		if (this.isEdit) {
			this.createdDate = this.dService.getTimeFromUtcDate(this.item.dateCreated);
			this.lastUpdated = this.dService.getTimeFromUtcDate(this.item.dateLastUpdated);
		}
		const dateStart = this.item?.startTime == null ? null : new Date(this.item?.startTime);
		const dateEnd = (this.item?.endTime == null) ? null : new Date(this.item?.endTime);
		this.eventForm.reset();
		this.title.setValue(this.item?.title);
		const properTimeStart = this.dService.getTimeFromUtcDate(dateStart);
		const properTimeEnd = this.dService.getTimeFromUtcDate(dateEnd);

		this.startDate.setValue(this.dateString(properTimeStart));
		this.startTime.setValue(this.timeString(properTimeStart));
		this.endDate.setValue(this.dateString(properTimeEnd));
		this.endTime.setValue(this.timeString(properTimeEnd));

		this.description.setValue(this.item?.description);
		this.isVirtual.setValue(this.item?.isVirtual === true);
		this.isHidden.setValue(this.item?.isHidden !== false);
		this.address.setValue(this.item?.address);
		this.city.setValue(this.item?.city);
		this.state.setValue(this.item?.state);
		this.zip.setValue(this.item?.zip);
		this.location.setValue(this.item?.location);
		this.linkUrl.setValue(this.item?.linkUrl);
		this.linkName.setValue(this.item?.linkName);
		this.fileUrl.setValue(this.item?.fileUrl);
		this.pdfFileLabel = this.item?.fileUrl?.split('/')?.pop() ?? 'Upload File';
		this.fileName.setValue(this.item?.fileName);
		this.fileUploadDate = this.item != null ? this.dService.getTimeFromUtcDate(this.item.fileUploadDate) : null;

		this.host1Name.setValue(this.item?.host1Name);
		this.host1Title.setValue(this.item?.host1Title);
		this.fileUrls[0] = this.item?.host1ImageUrl;
		this.fileLabels[0] = this.item?.host1ImageUrl?.split('/')?.pop() ?? 'Upload Image';
		this.host1ImageUrl.setValue(this.item?.host1ImageUrl);
		this.hasHost1.setValue(!this.isNullOrEmpty(this.item?.host1Name));

		this.host2Name.setValue(this.item?.host2Name);
		this.host2Title.setValue(this.item?.host2Title);
		this.fileUrls[1] = this.item?.host2ImageUrl;
		this.fileLabels[1] = this.item?.host2ImageUrl?.split('/')?.pop() ?? 'Upload Image';
		this.host2ImageUrl.setValue(this.item?.host2ImageUrl);
		this.hasHost2.setValue(!this.isNullOrEmpty(this.item?.host2Name));

		this.host3Name.setValue(this.item?.host3Name);
		this.host3Title.setValue(this.item?.host3Title);
		this.fileUrls[2] = this.item?.host3ImageUrl;
		this.fileLabels[2] = this.item?.host3ImageUrl?.split('/')?.pop() ?? 'Upload Image';
		this.host3ImageUrl.setValue(this.item?.host3ImageUrl);
		this.hasHost3.setValue(!this.isNullOrEmpty(this.item?.host3Name));

		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
	}

	isNullOrEmpty(prop: string) {
		return prop == null || prop === '';
	}

	createEventLeaf() {
		if (this.eventForm.invalid) {
			this.saveTry = true;
			return;
		}
		this.checkHosts();
		const dateParts = this.startDate.value.split('-');
		const stime = this.startTime.value.split(':');
		const newStartTime = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], stime[0], stime[1], 0, 0);
		let newEndTime: Date;
		if (this.endDate.value == null && this.endTime.value != null) {
			const etime = this.endTime.value.split(':');
			newEndTime = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], etime[0], etime[1], 0, 0);
		} else if (this.endDate.value != null) {
			const edateParts = this.endDate.value.split('-');
			const etime = this.endTime.value ? this.endTime.value.split(':') : this.startTime.value.split(':');
			newEndTime = new Date(edateParts[0], edateParts[1] - 1, edateParts[2], etime[0], etime[1], 0, 0);
		}
		this.save(new EventLeaf(
			0,
			this.title.value,
			newStartTime,
			newEndTime,
			this.description.value,
			this.isVirtual.value,
			this.location.value,
			this.address.value,
			this.city.value,
			this.state.value,
			this.zip.value,
			this.linkUrl.value,
			this.linkName.value,
			this.fileUrl.value,
			this.fileName.value,
			(this.fileUrl.value != null) ? this.fileUploadDate : null,
			this.host1Name.value,
			this.host1Title.value,
			this.host1ImageUrl.value,
			this.host2Name.value,
			this.host2Title.value,
			this.host2ImageUrl.value,
			this.host3Name.value,
			this.host3Title.value,
			this.host3ImageUrl.value,
			this.isHidden.value,
			this.pId,
			this.eTypeId,
		));
	}

	editEventLeaf() {
		if (this.eventForm.invalid) {
			this.saveTry = true;
			return;
		}
		const newItem = Object.assign({}, this.item);
		this.checkHosts();
		newItem.title = this.title.value;
		const dateParts = this.startDate.value.split('-');
		const stime = this.startTime.value.split(':');
		newItem.startTime = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], stime[0], stime[1], 0, 0);
		if (this.endDate.value == null && this.endTime.value != null) {
			const etime = this.endTime.value.split(':');
			newItem.endTime = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], etime[0], etime[1], 0, 0);
		} else if (this.endDate.value != null) {
			const edateParts = this.endDate.value.split('-');
			const etime = this.endTime.value ? this.endTime.value.split(':') : this.startTime.value.split(':');
			newItem.endTime = new Date(edateParts[0], edateParts[1] - 1, edateParts[2], etime[0], etime[1], 0, 0);
		}
		if (newItem.endTime != null && newItem.endTime < newItem.startTime) {
			this.saveTry = true;
			alert('End date/time cannot be before start date/time.');
			return;
		}

		newItem.description = this.description.value;
		newItem.isVirtual = this.isVirtual.value;
		newItem.isHidden = this.isHidden.value;
		newItem.location = this.location.value;
		newItem.address = this.address.value;
		newItem.city = this.city.value;
		newItem.state = this.state.value;
		newItem.zip = this.zip.value;
		newItem.linkUrl = this.linkUrl.value;
		newItem.linkName = this.linkName.value;
		newItem.fileUrl = this.fileUrl.value;
		newItem.fileName = this.fileName.value;
		newItem.fileUploadDate = (this.fileUrl.value != null) ? this.fileUploadDate : null,
		newItem.host1Name = this.host1Name.value;
		newItem.host1Title = this.host1Title.value;
		newItem.host1ImageUrl = this.host1ImageUrl.value;
		newItem.host2Name = this.host2Name.value;
		newItem.host2Title = this.host2Title.value;
		newItem.host2ImageUrl = this.host2ImageUrl.value;
		newItem.host3Name = this.host3Name.value;
		newItem.host3Title = this.host3Title.value;
		newItem.host3ImageUrl = this.host3ImageUrl.value;
		this.save(newItem);
	}

	checkHosts() {
		if (!this.hasHost1.value) {
			this.host1Name.setValue('');
			this.host1Title.setValue('');
			this.host1ImageUrl.setValue('');
			this.fileLabels[0] = null;
			this.fileUrls[0] = null;
		}
		if (!this.hasHost2.value) {
			this.host2Name.setValue('');
			this.host2Title.setValue('');
			this.host2ImageUrl.setValue('');
			this.fileLabels[1] = null;
			this.fileUrls[1] = null;
		}
		if (!this.hasHost3.value) {
			this.host3Name.setValue('');
			this.host3Title.setValue('');
			this.host3ImageUrl.setValue('');
			this.fileLabels[2] = null;
			this.fileUrls[2] = null;
		}
	}

	save(item: EventLeaf) {
		this.dService.upsertEventLeaf(item).subscribe(
			newLeaf => {
				alert(`Event successfully ${this.isEdit ? 'update' : 'created'}`);
				item.lastUpdateBy = this.dService.getCurrentUserEmailOrNull();
				if (!this.isEdit) {
					this.append.emit(newLeaf);
				} else {
					this.item.title = item.title;
					this.item.startTime = this.dService.convertDateToUTC(item.startTime);
					this.item.endTime = this.dService.convertDateToUTC(item.endTime);
					this.item.description = item.description;
					this.item.isVirtual = item.isVirtual;
					this.item.isHidden = item.isHidden;
					this.item.location = item.location;
					this.item.address = item.address;
					this.item.city = item.city;
					this.item.state = item.state;
					this.item.zip = item.zip;
					this.item.linkUrl = item.linkUrl;
					this.item.linkName = item.linkName;
					this.item.fileUrl = item.fileUrl;
					this.item.fileName = item.fileName;
					this.item.fileUploadDate = this.dService.convertDateToUTC(item.fileUploadDate);
					this.item.host1Name = item.host1Name;
					this.item.host1Title = item.host1Title;
					this.item.host1ImageUrl = item.host1ImageUrl;
					this.item.host2Name = item.host2Name;
					this.item.host2Title = item.host2Title;
					this.item.host2ImageUrl = item.host2ImageUrl;
					this.item.host3Name = item.host3Name;
					this.item.host3Title = item.host3Title;
					this.item.host3ImageUrl = item.host3ImageUrl;
					this.item.lastUpdateBy = item.lastUpdateBy;
					this.item.dateLastUpdated = newLeaf.dateLastUpdated;
					this.append.emit(null);
				}
				this.modalService.dismissAll(EventLeafModalComponent);
			}, exc => {
				alert('Event data not saved. ' + exc.error);
			},
		);
	}

	cancelEvent() {
		this.modalService.dismissAll(EventLeafModalComponent);
	}

	pdfFileChanged(event: any) {
		this.dService.uploadPdf(event)
			.subscribe(
				resp => {
					window.alert('Upload Successful');
					this.pdfFileLabel = resp.fileName;
					this.pdfFileUrl = resp.url;
					this.fileUrl.setValue(resp.url);
					this.fileUploadDate = new Date();
				},
				ex => window.alert(ex.error),
			);
	}

	imageFileChanged(event: any, id: number) {
		this.dService.uploadImage(event)
			.subscribe(
				resp => {
					window.alert('Upload Successful');
					this.fileLabels[id - 1] = resp.fileName;
					this.fileUrls[id - 1] = resp.url;
					if (id === 1) {
						this.host1ImageUrl.setValue(resp.url);
					} else if (id === 2) {
						this.host2ImageUrl.setValue(resp.url);
					} else {
						this.host3ImageUrl.setValue(resp.url);
					}
				},
				ex => window.alert(ex.error),
			);
	}

	removeFileUrl() {
		this.pdfFileLabel = 'Upload File';
		this.pdfFileUrl = null;
		this.fileName.setValue(null);
		this.fileUrl.setValue(null);
		this.fileUploadDate = null;
	}

	deleteEventLeaf() {
		const targetId = this.item.id;
		if (confirm('Are you sure you want to delete?')) {
			this.dService.deleteEventLeaf(targetId).subscribe(_ => {
				this.modalService.dismissAll(EventLeafModalComponent);
				alert('Event was successful deleted');
				this.delete.emit(targetId);
			}, _ => {
				alert('Error trying to delete event');
			});
		}
	}

	clearEnd() {
		this.endTime.setValue(null);
	}
}
