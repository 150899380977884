import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ResourceLeaf } from 'src/app/models/_module';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';


@Component({
	selector: 'pm-resource-leaf-modal',
	templateUrl: './resource-leaf-modal.component.html',
	styleUrls: ['./resource-leaf-modal.component.scss', '../styles.scss'],
})
export class ResourceLeafModalComponent implements OnInit {

	@Input() item: ResourceLeaf;
	@Input() pId: number;
	@Input() eTypeId: number;
	@Output() append: EventEmitter<ResourceLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();

	resourceLeafForm: FormGroup;
	isEdit: Boolean;
	isUpload = true;
	fileLabel = 'Upload Pdf File';
	fileUrl: string;
	fileUploadDate: Date;
	isUploadSelector: string;
	saveTry: boolean;
	pen = faPen;
	plus = faPlus;
	createdDate: Date;
	lastUpdated: Date;

	get displayOfChild() { return this.resourceLeafForm.get('displayOfChild'); }
	get valueOfChild() { return this.resourceLeafForm.get('valueOfChild'); }
	get pdfUrlOfChild() { return this.resourceLeafForm.get('pdfUrlOfChild'); }
	get linkUrlOfChild() { return this.resourceLeafForm.get('linkUrlOfChild'); }
	get resourceFilled() { return this.resourceLeafForm.get('resourceFilled'); }
	get isHidden() { return this.resourceLeafForm.get('isHidden'); }

	constructor(
		private modalService: NgbModal,
		private dService: DataService,
		private formbuilder: FormBuilder,
	) { }

	ngOnInit(): void {
		this.resourceLeafForm = this.formbuilder.group({
			displayOfChild: ['', [Validators.required, Validators.maxLength(24)]],
			valueOfChild: [''],
			pdfUrlOfChild: [{ value: '', disabled: true }],
			linkUrlOfChild: [''],
			isHidden: [false],
			resourceFilled: [null, [this.HasUrlLink('pdfUrlOfChild', 'linkUrlOfChild')]],
		});
		this.isEdit = this.item != null;
	}

	open(content: any) {
		if (this.isEdit) {
			this.createdDate = this.dService.getTimeFromUtcDate(this.item.dateCreated);
			this.lastUpdated = this.dService.getTimeFromUtcDate(this.item.dateLastUpdated);
		}
		this.resourceLeafForm.reset();
		this.saveTry = false;
		if (this.item != null && this.item.linkUrl != null && this.item.linkUrl !== '') {
			this.isUpload = false;
			this.isUploadSelector = 'link';
		} else {
			this.isUpload = true;
			this.isUploadSelector = 'upload';
			if (this.item?.pdfUrl != null && this.item.pdfUrl !== '') {
				this.fileLabel = this.item.pdfUrl.split('/')?.pop() ?? 'Filename n/a';
			}
		}
		this.displayOfChild.setValue(this.item?.display);
		this.valueOfChild.setValue(this.item?.value);
		this.pdfUrlOfChild.setValue(this.item?.pdfUrl);
		this.linkUrlOfChild.setValue(this.item?.linkUrl);
		this.isHidden.setValue(this.item?.isHidden);
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
		this.fileUploadDate = this.item != null ? this.dService.getTimeFromUtcDate(this.item.fileUploadDate) : null;
	}

	createChild() {
		if (this.resourceLeafForm.invalid) {
			this.saveTry = true;
			return;
		}
		const newItem: ResourceLeaf = {
			id: 0,
			display: this.displayOfChild.value,
			value: this.valueOfChild.value,
			pdfUrl: this.isUpload ? this.pdfUrlOfChild.value : null,
			linkUrl: !this.isUpload ? this.linkUrlOfChild.value : null,
			parentId: this.pId,
			parentTitle: null,
			entityTypeId: this.eTypeId,
			entityTypeName: null,
			isHidden: this.isHidden.value,
			lastUpdateBy: null,
			fileUploadDate: this.isUpload ? this.fileUploadDate : null,
			dateCreated: null,
			dateLastUpdated: null,
		};
		this.save(newItem);
	}

	editChild() {
		if (this.resourceLeafForm.invalid) {
			this.saveTry = true;
			return;
		}
		const newItem = Object.assign({}, this.item);
		newItem.display = this.displayOfChild.value;
		newItem.value = this.valueOfChild.value;
		newItem.pdfUrl = this.isUpload ? this.pdfUrlOfChild.value : null;
		newItem.linkUrl = !this.isUpload ? this.linkUrlOfChild.value : null;
		newItem.isHidden = this.isHidden.value;
		newItem.fileUploadDate = this.isUpload ? this.fileUploadDate : null;
		this.save(newItem);
	}

	save(item: ResourceLeaf) {
		// pass resource leaf obj into data service, to be added
		this.dService.upsertResourceLeaf(item).subscribe(newLeaf => {
			alert(`Content has been ${this.isEdit ? 'updated' : 'created'}`);
			if (!this.isEdit) {
				this.append.emit(newLeaf);
			} else {
				this.item.display = newLeaf.display;
				this.item.value = newLeaf.value;
				this.item.lastUpdateBy = newLeaf.lastUpdateBy;
				this.item.isHidden = newLeaf.isHidden;
				this.item.linkUrl = newLeaf.linkUrl;
				this.item.pdfUrl = newLeaf.pdfUrl;
				this.item.fileUploadDate = newLeaf.fileUploadDate;
			}
			this.modalService.dismissAll(ResourceLeafModalComponent);
		});
	}

	cancelResource() {
		this.modalService.dismissAll(ResourceLeafModalComponent);
	}

	deleteChild() {
		const targetId = this.item.id;
		if (confirm('Are you sure you want to delete?')) {
			this.dService.deleteResourceLeaf(targetId).subscribe(_ => {
				this.modalService.dismissAll(ResourceLeafModalComponent);
				alert('Content has been deleted');
				this.delete.emit(targetId);
			}, _ => {
				alert('Error trying to delete content');
			});
		}
	}

	fileChanged(event: any) {
		this.dService.uploadPdf(event)
			.subscribe(
				resp => {
					window.alert('Upload Successful');
					this.fileLabel = resp.fileName;
					this.fileUrl = resp.url;
					this.pdfUrlOfChild.setValue(this.fileUrl);
					this.fileUploadDate = new Date();
				},
				ex => window.alert(ex.error),
			);
	}

	onUrlTypeChange(type: string) {
		this.isUpload = type === 'upload';
		this.resourceFilled.updateValueAndValidity();
	}
	HasUrlLink(pdfInput: string, urlInput: string) {
		let hasLinkControl: FormControl;
		let pdfControl: FormControl;
		let urlControl: FormControl;

		return (control: FormControl) => {
			if (!control.parent) {
				return null;
			}
			if (!hasLinkControl) {
				hasLinkControl = control;
				pdfControl = control.parent.get(pdfInput) as FormControl;
				urlControl = control.parent.get(urlInput) as FormControl;
				// whenever display type changes, update validity
				pdfControl.valueChanges.subscribe(() => {
					hasLinkControl.updateValueAndValidity();
				});
				urlControl.valueChanges.subscribe(() => {
					hasLinkControl.updateValueAndValidity();
				});
			}
			if (this.isUpload && (pdfControl.value == null || pdfControl.value === '')) {
				return {
					pdfMissing: true,
				};
			}
			if (!this.isUpload && (urlControl.value == null || urlControl.value === '')) {
				return {
					urlMissing: true,
				};
			}
			return null;
		};
	}
}
