import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pm-star-rating',
	templateUrl: './star-rating.component.html',
	styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
	@Input() rating: number;
	@Input() ratingText: string;
	starArray: number[] = [];
	emptyArray: number[] = [];
	hasHalf = false;
	constructor() { }
	ngOnInit(): void {
		this.hasHalf = this.rating % 1 > 0;
		const filledStars = Math.floor(this.rating);
		const emptyStars = 5 - (filledStars + (this.hasHalf ? 1 : 0));
		for (let i = 0; i < (filledStars + emptyStars); i++) {
			if (i < filledStars) {
				this.starArray.push(1);
			} else {
				this.emptyArray.push(1);
			}
		}
	}
}
