<ng-template #content let-modal>
	<div class="modal-header d-flex justify-content-center">
		<h4 class="modal-title" id="modal-basic-title">Resource Item</h4>
		<button (click)="modal.dismiss()" type="button" class="close" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<form [formGroup]="resourceLeafForm">
			<div class="form-group">
				<div class="form-row">
					<label for="displayOfChild">Title:
						<span class="text-danger">*</span></label>
					<input id="displayOfChild" formControlName="displayOfChild" class="form-control" type="text" />
					<small *ngIf="displayOfChild.invalid && (displayOfChild.dirty || saveTry)"
						class="text-danger">**Title is required</small>
				</div>
				<div class="form-row pt-2">
					<label for="valueOfChild">Detail:</label>
					<input id="valueOfChild" formControlName="valueOfChild" class="form-control" type="text" />
				</div>
				<div class="form-row">
					<select #urlType (change)="onUrlTypeChange(urlType.value)" [value]="isUploadSelector"
						class="browser-default custom-select mb-3 mt-3">
						<option value="upload">Pdf Upload</option>
						<option value="link">Url Link</option>
					</select>
				</div>
				<div class="form-row" *ngIf="isUpload">
					<div class="custom-file">
						<input #fileInput (change)="fileChanged($event)" type="file" class="custom-file-input"
							id="selectedFile" lang="es" />
						<label class="custom-file-label" for="selectedFile">
							{{ fileLabel }}
						</label>
					</div>
				</div>
				<div *ngIf="isUpload" class="form-row mt-1">
					<label for="pdfUrlOfChild">File Upload Url:
						<span class="text-danger">*</span></label>
					<input id="pdfUrlOfChild" formControlName="pdfUrlOfChild" class="form-control" type="text" />
				</div>
				<div *ngIf="isUpload" class="form-row">
					<span *ngIf="fileUploadDate != null">Upload Date: {{fileUploadDate | date:'MMM dd, yyyy  hh:mm a'}}</span>
				</div>
				<div *ngIf="!isUpload" class="form-row">
					<label for="linkUrlOfChild">Link Url:
						<span class="text-danger">*</span></label>
					<input id="linkUrlOfChild" formControlName="linkUrlOfChild" class="form-control" type="text" />
				</div>
				<div class="form-row">
					<small *ngIf="resourceFilled.invalid && resourceFilled.errors.pdfMissing && saveTry"
						class="text-danger">**Pdf upload is required</small>
					<small *ngIf="resourceFilled.invalid && resourceFilled.errors.urlMissing && saveTry"
						class="text-danger">**Url link is required</small>
				</div>
				<div class="row mt-2">
					<div class="col px-2">
						<label for="entityTypeOfParent" class="bold">Folder Status:</label><br />
					</div>
				</div>
				<div class="row">
					<div class="col-12 px-2">
						<select class="custom-select" id="isHidden" formControlName="isHidden">
							<option value="" disabled>Choose Status</option>
							<option [ngValue]="false">Visible</option>
							<option [ngValue]="true">Hidden</option>
						</select>
					</div>
				</div>
				<div class="row mt-4" *ngIf="isEdit">
					<small class="col-6 text-muted">Created: <span *ngIf="createdDate; else not_applicable">{{createdDate | date:'MM/dd/yy'}}</span></small>
					<small class="col-6 text-muted text-right">Updated: <span *ngIf="lastUpdated; else not_applicable">{{lastUpdated | date:'MM/dd/yy'}}</span></small>
				</div>
			</div>
		</form>
	</div>

	<div class="modal-footer d-flex justify-content-between">
		<div>
			<button (click)="cancelResource()" type="button" class="btn btn-dark" aria-label="Cancel">Cancel</button>
		</div>
		<div>
			<button *ngIf="!isEdit" (click)="createChild()" id="createBtn" type="button" class="btn btn-outline-primary"
				aria-label="Create">Create</button>
			<button *ngIf="isEdit" (click)="editChild()" id="editBtn" type="button" class="btn btn-primary mr-2"
				aria-label="Save">Save</button>
			<button *ngIf="isEdit" (click)="deleteChild()" id="deleteBtn" type="button" class="btn btn-danger"
				aria-label="Delete">Delete</button>
		</div>
	</div>
</ng-template>

<ng-template #not_applicable>N/A</ng-template>

<button *ngIf="isEdit" class="btn penBtn btn-background px-1 py-0" (click)="open(content)">
	<span>
		<span class="sr-only">Edit</span>
		<!-- <img src="../../../assets/imgs/edit.svg" class="buttonImage" alt="Edit" aria-hidden="true"> -->
		<fa-icon [icon]="pen" class="blueIcon" alt="Edit" aria-hidden="true"></fa-icon>
	</span>
</button>
<button *ngIf="!isEdit" class="btn plusBtn bg-primary px-1 py-0" (click)="open(content)">
	<fa-icon class="fa-xs whiteIcon" [icon]="plus"></fa-icon>
	<span class="whiteIcon pl-1 m-0">Add</span>
</button>
