<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Event item</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form [formGroup]="eventForm">
			<!-- title/detail container -->
			<div class="form-group">
				<div class="form-row">
					<div class="col-12">
						<label for="title">Title:&nbsp;<span class="text-danger">*</span></label>
						<input id="title" formControlName="title" class="form-control" type="text" />
						<small class="text-danger" *ngIf="title.invalid && title.dirty">
							**Title is invalid
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="col-6 pt-2">
						<label for="">Start Date:&nbsp;<span class="text-danger">*</span></label>
						<div class="input-group">
							<input type="date" id="startDate" [value]="startDate" formControlName="startDate"
								class="form-control bg-white" />
						</div>
						<small class="text-danger" *ngIf="startDate.invalid && startDate.dirty">
							**Start Date is invalid
						</small>
					</div>
					<div class="col-6 pt-2">
						<label for="">Start Time:&nbsp;<span class="text-danger">*</span></label>
						<div class="input-group">
							<input type="time" id="startTime" [value]="startTime" formControlName="startTime"
								class="form-control bg-white" />
						</div>
						<small class="text-danger" *ngIf="startTime.invalid && startTime.dirty">
							**Start Time is invalid
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="col-6 pt-2">
						<label for="">End Date:&nbsp;</label>
						<div class="input-group">
							<input type="date" id="endDate" [value]="endDate" formControlName="endDate"
								class="form-control bg-white" />
							<!-- ngbDatepicker #e="ngbDatepicker"> -->
							<div class="input-group-append">
								<button class="btn btn-outline-secondary" (click)="clearEnd()">
									&times;
								</button>
							</div>
						</div>
						<small class="text-danger" *ngIf="endDate.invalid && endDate.dirty">
							**End Date is invalid {{ endDate.errors | json }}
						</small>
					</div>
					<div class="col-6 pt-2">
						<label for="">End Time:&nbsp;</label>
						<div class="input-group">
							<input type="time" id="endTime" [value]="endTime" formControlName="endTime"
								class="form-control bg-white" />
							<!-- ngbDatepicker #e="ngbDatepicker"> -->
							<div class="input-group-append">
								<button class="btn btn-outline-secondary" (click)="clearEnd()">
									&times;
								</button>
							</div>
						</div>
						<small class="text-danger" *ngIf="endTime.invalid && endTime.dirty">
							**End Time is invalid {{ endTime.errors | json }}
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="col-12 pt-2">
						<label for="description">Description:&nbsp;</label>
						<input id="description" formControlName="description" class="form-control" type="text" />
						<small class="text-danger" *ngIf="description.invalid && description.dirty">
							**Description is invalid
						</small>
					</div>
				</div>
			</div>
			<!-- End of title/description -->

			<!-- Location/Link -->
			<div class="form-group">
				<div class="form-row custom-control custom-checkbox mb-2">
					<div class="col-12">
						<input type="checkbox" class="custom-control-input" id="isVirtual"
							formControlName="isVirtual" />
						<label class="custom-control-label" for="isVirtual">Is Virtual?</label>
						<br />
						<small class="text-danger" *ngIf="isVirtual.invalid && (isVirtual.dirty || saveTry)">
							**Link Url required if checked
						</small>
					</div>
				</div>
				<div class="form-row" *ngIf="!isVirtual.value">
					<div class="col-md-6 col-12 pt-1">
						<label for="location">Location:&nbsp;</label>
						<input id="location" formControlName="location" class="form-control" type="text" />
						<small class="text-danger" *ngIf="location.invalid && location.dirty">
							**Location is invalid
						</small>
					</div>
					<div class="col-md-6 col-12 pt-1">
						<label for="address">Address:&nbsp;</label>
						<input id="address" formControlName="address" class="form-control" type="text" />
						<small class="text-danger" *ngIf="address.invalid && address.dirty">
							**Address is invalid
						</small>
					</div>
				</div>
				<div class="form-row" *ngIf="!isVirtual.value">
					<div class="col-lg-4 col-12 pt-2">
						<label for="city">City:&nbsp;</label>
						<input id="city" formControlName="city" class="form-control" type="text" />
						<small class="text-danger" *ngIf="city.invalid">
							**City is required
						</small>
					</div>
					<div class="col-lg-4 col-6 pt-2">
						<label for="location">State:&nbsp;</label>
						<select class="custom-select" formControlName="state">
							<option value="" disabled>Choose State</option>
							<option value="AL">AL - Alabama</option>
							<option value="AK">AK - Alaska</option>
							<option value="AZ">AR - Arizona</option>
							<option value="AR">AZ - Arkansas</option>
							<option value="CA">CA - California</option>
							<option value="CO">CO - Colorado</option>
							<option value="CT">CT - Connecticut</option>
							<option value="DE">DC - Delaware</option>
							<option value="DC">DE - District Of Columbia</option>
							<option value="FL">FL - Florida</option>
							<option value="GA">GA - Georgia</option>
							<option value="HI">HI - Hawaii</option>
							<option value="ID">IA - Idaho</option>
							<option value="IL">ID - Illinois</option>
							<option value="IN">IL - Indiana</option>
							<option value="IA">IN - Iowa</option>
							<option value="KS">KS - Kansas</option>
							<option value="KY">KY - Kentucky</option>
							<option value="LA">LA - Louisiana</option>
							<option value="ME">MA - Maine</option>
							<option value="MD">MD - Maryland</option>
							<option value="MA">ME - Massachusetts</option>
							<option value="MI">MI - Michigan</option>
							<option value="MN">MN - Minnesota</option>
							<option value="MS">MO - Mississippi</option>
							<option value="MO">MS - Missouri</option>
							<option value="MT">MT - Montana</option>
							<option value="NE">NC - Nebraska</option>
							<option value="NV">NE - Nevada</option>
							<option value="NH">NH - New Hampshire</option>
							<option value="NJ">NJ - New Jersey</option>
							<option value="NM">NM - New Mexico</option>
							<option value="NY">NV - New York</option>
							<option value="NC">NY - North Carolina</option>
							<option value="ND">ND - North Dakota</option>
							<option value="OH">OH - Ohio</option>
							<option value="OK">OK - Oklahoma</option>
							<option value="OR">OR - Oregon</option>
							<option value="PA">PA - Pennsylvania</option>
							<option value="RI">RI - Rhode Island</option>
							<option value="SC">SC - South Carolina</option>
							<option value="SD">SD - South Dakota</option>
							<option value="TN">TN - Tennessee</option>
							<option value="TX">TX - Texas</option>
							<option value="UT">UT - Utah</option>
							<option value="VT">VT - Vermont</option>
							<option value="VA">VA - Virginia</option>
							<option value="WA">WA - Washington</option>
							<option value="WV">WI - West Virginia</option>
							<option value="WI">WV - Wisconsin</option>
							<option value="WY">WY - Wyoming</option>
						</select>
						<small class="text-danger" *ngIf="state.invalid">
							**State is required
						</small>
					</div>
					<div class="col-lg-4 col-6 pt-2">
						<label for="address">Zip:&nbsp;</label>
						<input id="zip" formControlName="zip" class="form-control" type="text" />
						<small class="text-danger" *ngIf="zip.invalid">
							**Zip is required
						</small>
					</div>
				</div>
				<!-- End of Location -->

				<!-- Link/File -->
				<div class="form-row">
					<div class="col-12 pt-2">
						<label for="linkUrl">Link URL:&nbsp;</label>
						<input id="linkUrl" formControlName="linkUrl" class="form-control" type="text" />
						<small class="text-danger" *ngIf="linkUrl.invalid && linkUrl.dirty">
							**Link URL is invalid
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="col-12 pt-2">
						<label for="linkName">Link Name:&nbsp;</label>
						<input id="linkName" formControlName="linkName" class="form-control" type="text" />
						<small class="text-danger" *ngIf="linkName.invalid && linkName.dirty">
							**Link Name is invalid
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="col-12 pt-2 d-flex justify-content-between">
						<label class="d-inline-block align-center mb-0" for="selectedResFile">Pdf File</label>
						<button *ngIf="pdfFileLabel != 'Upload File'" class="btn btn-link p-0" (click)="removeFileUrl()">Remove</button>
					</div>
					<div class="col-12 pt-2 d-flex">
						<div class="custom-file">
							<input type="file" class="custom-file-input" id="selectedResFile" lang="es" #fileInput
								(change)="pdfFileChanged($event)" />
							<label class="custom-file-label" for="selectedResFile">
								{{ pdfFileLabel }}
							</label>
						</div>
					</div>
				</div>
				<div class="form-row" *ngIf="pdfFileLabel != 'Upload File'">
					<div class="col-12 pt-2">
						<label for="fileUrl">Pdf File Url:&nbsp;</label>
						<input id="fileUrl" formControlName="fileUrl" class="form-control" type="text"
							readonly />
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="col-12">
					<label for="fileName">Pdf File Name:&nbsp;</label>
					<input id="fileName" formControlName="fileName" class="form-control" type="text" />
					<small class="text-danger" *ngIf="fileName.invalid && fileName.dirty">
						**Link Name is invalid
					</small>
				</div>
			</div>
			<div *ngIf="pdfFileLabel != 'Upload File'" class="form-row">
				<div class="col-12">
					<span *ngIf="fileUploadDate != null">Upload Date: {{fileUploadDate | date:'MMM dd, yyyy  hh:mm a'}}</span>
				</div>
			</div>
			<!-- End of Link/File -->

			<!-- Host Data -->
			<div class="form-group mt-3">
				<div class="form-row custom-control custom-checkbox mb-2">
					<div class="col-12">
						<input type="checkbox" class="custom-control-input" id="hasHost1" formControlName="hasHost1" />
						<label class="custom-control-label" for="hasHost1">Has Host(s)?</label>
					</div>
				</div>
			</div>

			<div class="form-group" *ngIf="hasHost1.value">
				<label class="mb-1 "><u>Host 1</u></label>
				<div class="form-row">
					<div class="col-6">
						<label for="host1Name">Name:&nbsp;</label>
						<input id="host1Name" formControlName="host1Name" class="form-control" type="text" />
						<small class="text-danger" *ngIf="host1Name.invalid && host1Name.dirty">
							**Host 1 Name is invalid
						</small>
					</div>
					<div class="col-6">
						<label for="host1Title">Title:&nbsp;</label>
						<input id="host1Title" formControlName="host1Title" class="form-control" type="text" />
						<small class="text-danger" *ngIf="host1Title.invalid && host1Title.dirty">
							**Host 1 Title is invalid
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="col-12 pt-2">
						<label for="selectedFile">Image</label>
						<div class="custom-file">
							<input type="file" class="custom-file-input" id="selectedFile" lang="es" #fileInput
								(change)="imageFileChanged($event, 1)" />
							<label class="custom-file-label" for="selectedFile">
								{{ fileLabels[0] }}
							</label>
						</div>
					</div>
				</div>
				<div class="form-row" *ngIf="fileUrls[0]">
					<div class="col-12 pt-2">
						<label for="host1ImageUrl">Image Url:&nbsp;</label>
						<input id="host1ImageUrl" formControlName="host1ImageUrl" class="form-control" type="text"
							readonly />
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="hasHost1.value || hasHost2.value">
				<div class="form-row custom-control custom-checkbox mt-2">
					<div class="col-12">
						<input type="checkbox" class="custom-control-input" id="hasHost2" formControlName="hasHost2" />
						<label class="custom-control-label" for="hasHost2">Has 2nd Host?</label>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="hasHost2.value">
				<label class="mb-0"><u>Host 2</u></label>
				<div class="form-row">
					<div class="col-6">
						<label for="host2Name">Name:&nbsp;</label>
						<input id="host2Name" formControlName="host2Name" class="form-control" type="text" />
						<small class="text-danger" *ngIf="host2Name.invalid && host2Name.dirty">
							**Host 2 Name is invalid
						</small>
					</div>
					<div class="col-6">
						<label for="host2Title">Title:&nbsp;</label>
						<input id="host2Title" formControlName="host2Title" class="form-control" type="text" />
						<small class="text-danger" *ngIf="host2Title.invalid && host2Title.dirty">
							**Host 2 Title is invalid
						</small>
					</div>
				</div>

				<div class="form-row">
					<div class="col-12 pt-2">
						<label for="selectedFile">Image</label>
						<div class="custom-file">
							<input type="file" class="custom-file-input" id="selectedFile" lang="es" #fileInput
								(change)="imageFileChanged($event, 2)" />
							<label class="custom-file-label" for="selectedFile">
								{{ fileLabels[1] }}
							</label>
						</div>
					</div>
				</div>
				<div class="form-row" *ngIf="fileUrls[1]">
					<div class="col-12 pt-2">
						<label for="host2ImageUrl">Image Url:&nbsp;</label>
						<input id="host2ImageUrl" formControlName="host2ImageUrl" class="form-control" type="text"
							readonly />
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="hasHost2.value || hasHost3.value">
				<div class="form-row custom-control custom-checkbox mt-2">
					<div class="col-12">
						<input type="checkbox" class="custom-control-input" id="hasHost3" formControlName="hasHost3" />
						<label class="custom-control-label" for="hasHost3">Has 3rd Host?</label>
					</div>
				</div>
			</div>

			<div class="form-group" *ngIf="hasHost3.value">
				<label class="mb-0"><u>Host 3</u></label>
				<div class="form-row">
					<div class="col-6">
						<label for="host3Name">Name:&nbsp;</label>
						<input id="host3Name" formControlName="host3Name" class="form-control" type="text" />
						<small class="text-danger" *ngIf="host3Name.invalid && host3Name.dirty">
							**Host 3 Name is invalid
						</small>
					</div>
					<div class="col-6">
						<label for="host3Title">Title:&nbsp;</label>
						<input id="host3Title" formControlName="host3Title" class="form-control" type="text" />
						<small class="text-danger" *ngIf="host3Title.invalid && host3Title.dirty">
							**Host 3 Title is invalid
						</small>
					</div>
				</div>
				<div class="form-row">
					<div class="col-12 pt-2">
						<label for="selectedFile">Image</label>
						<div class="custom-file">
							<input type="file" class="custom-file-input" id="selectedFile" lang="es" #fileInput
								(change)="imageFileChanged($event, 3)" />
							<label class="custom-file-label" for="selectedFile">
								{{ fileLabels[2] }}
							</label>
						</div>
					</div>
				</div>
				<div class="form-row" *ngIf="fileUrls[2]">
					<div class="col-12 pt-2">
						<label for="host3ImageUrl">Image Url:&nbsp;</label>
						<input id="host3ImageUrl" formControlName="host3ImageUrl" class="form-control" type="text"
							readonly />
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
				  <label for="entityTypeOfParent" class="bold">Event Status:</label><br />
				</div>
			  </div>
			<div class="row">
				<div class="col-12">
				  <select class="custom-select" id="isHidden" formControlName="isHidden">
					<option value="" disabled>Choose Status</option>
					<option value="false">Visible</option>
					<option value="true">Hidden</option>
				  </select>
				</div>
			</div>
			<div class="row mt-4" *ngIf="isEdit">
				<small class="col-6 text-muted">Created: <span *ngIf="createdDate; else not_applicable">{{createdDate | date:'MM/dd/yy'}}</span></small>
				<small class="col-6 text-muted text-right">Updated: <span *ngIf="lastUpdated; else not_applicable">{{lastUpdated | date:'MM/dd/yy'}}</span></small>
			</div>
		</form>
	</div>
	<div class="modal-footer d-flex justify-content-between">
		<div>
			<button type="button" class="btn btn-dark" (click)="cancelEvent()">
				Cancel
			</button>
		</div>
		<div>
			<button *ngIf="!isEdit" id="createBtn" type="button" class="btn btn-outline-primary"
				(click)="createEventLeaf()">
				Create
			</button>
			<button *ngIf="isEdit" id="editBtn" type="button" class="btn btn-primary mr-2" (click)="editEventLeaf()">
				Save
			</button>
			<button *ngIf="isEdit" id="deleteBtn" type="button" class="btn btn-danger" (click)="deleteEventLeaf()">
				Delete
			</button>
		</div>
	</div>
</ng-template>

<ng-template #not_applicable>N/A</ng-template>

<button  *ngIf="isEdit" class="btn penBtn btn-background px-1 py-0" (click)="open(content)">
	<span>
		<span class="sr-only">Edit</span>
		<!-- <img src="../../../assets/imgs/edit.svg" class="buttonImage" alt="Edit" aria-hidden="true"> -->
		<fa-icon [icon]="pen" class="blueIcon" alt="Edit" aria-hidden="true"></fa-icon>
	</span>
</button>
<button *ngIf="!isEdit" class="btn plusBtn bg-primary px-1 py-0" (click)="open(content)">
	<fa-icon class="fa-xs whiteIcon" [icon]="plus"></fa-icon>
	<span class="whiteIcon pl-1 m-0">Add</span>
</button>
