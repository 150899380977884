<div class="card float" style="width: 18rem;">
	<div class="card-body" [ngClass]="{'pb-1': item.lastUpdateBy}">
		<div class="row">
			<div class="col-12">
				<b>
					<p class="card-text">
						{{ item.display? item.display : "N/a" }}
					</p>
				</b>
			</div>
		</div>
		<div>
			<a *ngIf="item.linkUrl" href="{{ item.linkUrl }}" class="card-text">
				{{ item.linkUrl }}
			</a>
			<a *ngIf="item.pdfUrl" href="{{ item.pdfUrl }}" class="card-text">
				{{ item.pdfUrl.split('/').pop() }}
			</a>
		</div>
		<div class="row mt-3 pb-2">
			<div class="col-6">
				<fa-icon *ngIf="!item.isHidden" class="fa-xs greyIcon" [icon]="eye"></fa-icon>
				<fa-icon *ngIf="item.isHidden" class="fa-xs greyIcon" [icon]="eyeSlash"></fa-icon>
				<span class="iconText pl-1 m-0">{{ item.isHidden? "Hidden" : "Visible" }}</span>
			</div>
			<div class="col-6 d-flex justify-content-end">
				<pm-resource-leaf-modal [item]="item" (append)="appendChild($event)" (delete)="deleteChild($event)">
				</pm-resource-leaf-modal>
			</div>
		</div>
	</div>
</div>