<div class="border border-dark bg-light container-fluid" style="width:100%">
	<div class="row">
		<div class="col-6 col-md-4 d-flex align-self-center">
			<h5>{{ item.display }}</h5>
		</div>
		<div class="col-6 col-md-5 d-flex flex-column py-3">
			<p>{{ item.value }}</p>
			<div *ngIf="item.linkUrl" class="d-flex">
				<div class="font-weight-bolder mr-1">Link:</div>
				<a href="{{ item.linkUrl }}">{{ item.linkUrl }}</a>
			</div>
			<div *ngIf="item.pdfUrl" class="d-flex">
				<div class="font-weight-bolder mr-1">PDF:</div>
				<a href="{{ item.pdfUrl }}">{{ item.pdfUrl.split('/').pop() }}</a>
			</div>
		</div>
		<div class="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-center align-items-md-end py-2">
			<pm-resource-leaf-modal
				[item]="item"
				(append)="appendChild($event)"
				(delete)="deleteChild($event)"
			></pm-resource-leaf-modal>
			<div class="ml-2 ml-md-0 mt-md-2">
				<pm-hidden-btn [item]="item" [type]="type"></pm-hidden-btn>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="item.lastUpdateBy">
		<small class="col-12 d-flex justify-content-center">
			Last update by: {{item.lastUpdateBy}}
		</small>
	</div>
</div>
