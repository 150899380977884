<ng-template #content let-modal>

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Contact item</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="contactForm">
            <div class="form-row">
                <div class="form-group col-6">
                    <label for="firstName">
                        First Name:
                        <span class="text-danger">*</span>
                    </label>
                    <input maxlength="50" id="firstName" formControlName="firstName" class="form-control" type="text">
                    <small class="text-danger" *ngIf="firstName.invalid && (firstName.dirty || saveTry)">
                        **First Name
                        is invalid
                    </small>
                </div>

                <div class="form-group col-6">
                    <label for="lastName">
                        Last Name:
                        <span class="text-danger">*</span>
                    </label>
                    <input maxlength="50" id="lastName" formControlName="lastName" class="form-control" type="text">
                    <small class="text-danger" *ngIf="lastName.invalid && (lastName.dirty || saveTry)">
                        **Last Name is
                        invalid
                    </small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-6">
                    <label for="title">Job Title: </label>
                    <input maxlength="50" id="title" formControlName="title" class="form-control" type="text">
                    <small class="text-danger" *ngIf="title.invalid && title.dirty"> **Job Title is invalid </small>
                </div>

                <div class="form-group col-6">
                    <label for="department">Department: </label>
                    <input maxlength="50" id="department" formControlName="department" class="form-control" type="text">
                    <small class="text-danger" *ngIf="department.invalid && department.dirty">
                        **Department is invalid
                    </small>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label for="program">Program: </label>
                    <input maxlength="50" id="program" formControlName="program" class="form-control" type="text">
                    <small class="text-danger" *ngIf="program.invalid && program.dirty"> **Program is invalid </small>
                </div>
                <div class="form-group col-6">
                    <label for="location">Location: </label>
                    <input maxlength="50" id="location" formControlName="location" class="form-control" type="text">
                    <small class="text-danger" *ngIf="location.invalid && location.dirty">
                        **Location is invalid
                    </small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-9">
                    <label for="phone">Phone Number: </label>
                    <input maxlength="18" id="phone" formControlName="phone" class="form-control" type="text">
                    <small class="text-danger" *ngIf="phone.invalid && phone.dirty"> **Phone Number is invalid </small>
                </div>

                <div class="form-group col-3">
                    <label for="phoneExtension">Ext.: </label>
                    <input maxlength="10" id="phoneExtension" formControlName="phoneExtension" class="form-control"
                           type="text">
                    <small class="text-danger" *ngIf="phoneExtension.invalid && phoneExtension.dirty">
                        **Phone Extension
                        is invalid
                    </small>
                </div>
            </div>

            <div class="form-group">
                <label for="email">Email Address: </label>
                <input maxlength="50" id="email" formControlName="email" class="form-control" type="text">
                <small class="text-danger" *ngIf="email.invalid && email.dirty"> **Email Address is invalid </small>
            </div>

            <div class="form-group">
                <label for="selectedFile">Contact Image</label>
                <div class="custom-file">
                    <input type="file"
                           class="custom-file-input"
                           id="selectedFile"
                           lang="es"
                           #fileInput
                           (change)="fileChanged($event)" />
                    <label class="custom-file-label" for="selectedFile">
                        {{ fileLabel }}
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label for="imageUrl">Contact Image Url: </label>
                <input id="imageUrl"
                       formControlName="imageUrl"
                       class="form-control"
                       type="text" />
            </div>

            <div class="custom-control custom-checkbox" *ngIf="!isEdit">
                <input type="checkbox" class="custom-control-input" id="createUser" formControlName="createUser">
                <label class="custom-control-label" for="createUser">Create user from this contact</label><br>
                <small class="text-danger" *ngIf="createUser.invalid && (createUser.dirty || saveTry)">
                    **Email required
                    if checked
                </small>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label for="entityTypeOfParent" class="bold">Contact Status:</label><br />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <select class="custom-select" id="isHidden" formControlName="isHidden">
                        <option value="" disabled>Choose Status</option>
                        <option [ngValue]="false">Visible</option>
                        <option [ngValue]="true">Hidden</option>
                    </select>
                </div>
            </div>
            <div class="row mt-2" *ngIf="isEdit">
                <div class="col">
                    <label for="entityTypeOfParent" class="bold">Folder Location:</label><br />
                </div>
                <!--<div class="col-12">
                    <select class="custom-select" id="isHidden" formControlName="folderLocation">
                        <option *ngFor="let l of list" [ngValue]="l.id" (click)="AddtoArray(l.id)">{{l.title}}</option>
                    </select>
                </div>-->
                <div class="col-12">
                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="list" formControlName="folderLocation" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event.id)" (onDeSelect)="onItemDeSelect($event.id)">
                    </ng-multiselect-dropdown>
                </div>
                <small class="col-6 text-muted mt-4">Created: <span *ngIf="createdDate; else not_applicable">{{createdDate | date:'MM/dd/yy'}}</span></small>
                <small class="col-6 text-muted mt-4 text-right">Updated: <span *ngIf="lastUpdated; else not_applicable">{{lastUpdated | date:'MM/dd/yy'}}</span></small>
            </div>
        </form>
    </div>

    <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-dark" (click)="cancelContact()">Cancel</button>
        <button type="button" *ngIf="isEdit" class="ml-2 btn btn-outline-primary" (click)="createNewUser()">Create User</button>
        <div>
            <button *ngIf="!isEdit" id="createBtn" type="button" class="btn btn-outline-primary" (click)="createContactLeaf()">
                Create
            </button>
            <button *ngIf="isEdit" id="editBtn" type="button" class="btn btn-primary mr-2" (click)="editContactLeaf()">
                Save
            </button>
            <button *ngIf="isEdit" id="deleteBtn" type="button" class="btn btn-danger" (click)="deleteContactLeaf()">
                Delete
            </button>
        </div>
    </div>

</ng-template>

<ng-template #not_applicable>N/A</ng-template>

<button *ngIf="isEdit" class="btn penBtn btn-background px-1 py-0" (click)="open(content)">
    <span>
        <span class="sr-only">Edit</span>
        <fa-icon [icon]="pen" class="blueIcon" alt="Edit" aria-hidden="true"></fa-icon>
    </span>
</button>
<button *ngIf="!isEdit" class="btn plusBtn bg-primary px-1 py-0" (click)="open(content)">
    <fa-icon class="fa-xs whiteIcon" [icon]="plus"></fa-icon>
    <span class="whiteIcon pl-1 m-0">Add</span>
</button>
