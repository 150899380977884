import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/_modules';
import { Info } from 'src/app/models/_module';

@Component({
	templateUrl: './app-info-page.component.html',
	styleUrls: ['./app-info-page.component.scss'],
})
export class AppInfoPageComponent implements OnInit {
	title = '';
	header = 'App Info';
	resourceUrl = 'default.png';
	appInfo: Info;
	htmlAboutUs: string;

	constructor(private dService: DataService) {}

	ngOnInit(): void {
		this.dService.getAppInfo().subscribe(x => {
			this.appInfo = x;
			this.title = x.projectName;
			try {
				this.htmlAboutUs = JSON.parse(x.aboutUs).split('\n\n').join('<br/><br/>').split('\n').join('<br/>');
			} catch {
				this.htmlAboutUs = x.aboutUs;
			}
		});
	}

	refreshPage() {
		location.reload();
	}
}
