import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Parent, DashboardResponse } from 'src/app/models/_module';
import { ViewportRuler } from '@angular/cdk/overlay';
import { DragNDropComponent } from 'src/app/models/dragndropcomp';
import { DataService } from 'src/app/services/_modules';

@Component({
	templateUrl: './dashboard-page.component.html',
	styleUrls: ['./dashboard-page.component.scss'],
})

export class DashboardPageComponent extends DragNDropComponent<Parent> implements OnInit, AfterViewInit {
	modalForm: FormGroup;

	// create variable to store array of parent objects
	pImages = [];
	pEntityTypes = [];
	pChildDisplayTypes = [];
	eTypeId: number;
	imageId: number;
	cDisplayId: number;
	title = '';
	header = '';
	resourceUrl = 'default.png';
	errorMessage: string;
	item: any;
	isEdit = false;

	constructor(private dService: DataService, public router: Router,
		viewportRuler: ViewportRuler, private activeRouted: ActivatedRoute) {
		super(viewportRuler);
	}

	ngOnInit(): void {
		const { data, error }: { data: DashboardResponse, error: string } = this.activeRouted.snapshot.data['dashboard'];
		this.id = 0;
		this.header = 'App Toolkit';
		if (error) {
			this.errorMessage = error;
		} else {
			this.title = data.project.name;
			this.dataList = data.dashboard;
		}
	}

	ngAfterViewInit() {
		this.setPhElement();
	}

	check(item){
		this.item = item;
	}

	appendPage(p: Parent) {
		if (p != null) {
			this.dataList.push(p);
		}
	}

	deletePage(id: number) {
		this.dataList = this.dataList.filter(x => x.id !== id);
	}

	refreshPage() {
		// refresh page to display updated data from the database
		location.reload();
	}

	actionMove(id: number, movingId: number, targetIndex: number) {
		this.dService.moveParentToIndex(id, movingId, targetIndex)
			.subscribe(x => {
				if (!x) {
					alert('Unable to save order changes right now. Try again later.');
					this.refreshPage();
				}
			}, ex => {
				const code = ex.status / 100;
				if (code === 4) {
					alert('Error while trying to save: Bad Request');
				} else if (code === 5) {
					alert('Error while trying to save: Server Error');
				}
				this.refreshPage();
			});
	}
}
