import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/_modules';

@Component({
	templateUrl: './users-page.component.html',
	styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent implements OnInit {
	listOfUsers: User[];
	title = '';
	header = 'Users'
	resourceUrl = 'default.png';
	currentUserEmail = '';
	addUser = false;
	editUser = true;
	constructor(
		private dService: DataService,
	) { }

	ngOnInit(): void {
		this.dService.getUsers().subscribe(x => {
			this.listOfUsers = x;
		});

		this.dService.getProjectById(environment.projectId).subscribe(x => {
			this.title = x.name;
		});

		this.currentUserEmail = this.dService.getCurrentUserEmailOrNull();
	}

	deleteUser(id: number) {
		this.listOfUsers = this.listOfUsers.filter(x => x.id !== id);
	}
	appendUser(newUser: User) {
		if (newUser != null) {
			this.listOfUsers.push(newUser);
		}
	}
}
