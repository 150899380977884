import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	BasicLeaf, ChildDisplayType, ContactLeaf,
	EntityType, EventLeaf, ImageData,
	Info, LoginRequest, ResourceLeaf,
	Parent, Project, User, HtmlLeaf,
	DashboardResponse, ParentPage, AppStats,
} from '../models/_module';

@Injectable({
	providedIn: 'root',
})

export class DataService {

	constructor(private http: HttpClient) {
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this.token = currentUser ? currentUser.token : null;
	}

	public api = environment.api;
	public projectId = environment.projectId;
	public token: string;

	private httpGet<T>(path: string): Observable<T> {
		return this.http.get<T>(`${this.api}/${path}`);
	}
	private httpPost<T>(path: string, obj: any): Observable<T> {
		return this.http.post<T>(`${this.api}/${path}`, obj);
	}
	private httpDelete<T>(path: string): Observable<T> {
		return this.http.delete<T>(`${this.api}/${path}`);
	}

	// Parent Endpoints
	getDashboard(): Observable<DashboardResponse> {
		return this.httpGet<DashboardResponse>(`parent/project/dashboard`);
	}
	getProjectById(id: number): Observable<Project> {
		return this.httpGet<Project>(`project/${id}`);
	}
	getProjectAppStats(): Observable<AppStats> {
		return this.httpGet<AppStats>(`HtmlScrub`);
	}
	getParentsByProjectId(id: number): Observable<Parent[]> {
		return this.httpGet<Parent[]>(`parent/project/${id}`);
	}
	getParentsByParentId(id: number): Observable<Parent[]> {
		return this.httpGet<Parent[]>(`parent/listByParentId/${id}`);
	}
	getParentById(id: number): Observable<Parent> {
		return this.httpGet<Parent>(`parent/${id}`);
	}
	getParentPageById(id: number): Observable<ParentPage> {
		return this.httpGet<ParentPage>(`parent/page/${id}`);
	}
	upsertParent(parent: Parent): Observable<number> {
		return this.httpPost<number>(`parent`, parent);
	}
	deleteParent(id: number): Observable<boolean> {
		return this.httpDelete<boolean>(`parent/${id}`);
	}
	moveParentToIndex(parentId: number, movingId: number, toIndex: number): Observable<boolean> {
		return this.httpPost<boolean>(`parent/move/${parentId}/${movingId}/${toIndex}`, null);
	}
	moveToParentId(type: string, parent: Parent, newParentId: number): Observable<number> {
		return this.httpPost<number>(`${type}/move/toParent/${newParentId}`, parent);
	}

	// Basic Leaf
	getBasicLeafsByParentId(id: number): Observable<BasicLeaf[]> {
		return this.httpGet<BasicLeaf[]>(`basicLeaf/parent/${id}`);
	}
	upsertBasicLeaf(leaf: BasicLeaf): Observable<BasicLeaf> {
		return this.httpPost<BasicLeaf>(`basicLeaf`, leaf);
	}
	deleteBasicLeaf(id: number): Observable<boolean> {
		return this.httpDelete<boolean>(`basicLeaf/${id}`);
	}

	moveBasicToIndex(parentId: number, movingId: number, toIndex: number): Observable<boolean> {
		return this.httpPost<boolean>(`basicLeaf/move/${parentId}/${movingId}/${toIndex}`, null);
	}

	// Resource Leaf
	getResourceLeafsByParentId(id: number): Observable<ResourceLeaf[]> {
		return this.httpGet<ResourceLeaf[]>(`resourceLeaf/parent/${id}`);
	}
	upsertResourceLeaf(leaf: ResourceLeaf): Observable<ResourceLeaf> {
		return this.httpPost<ResourceLeaf>(`resourceLeaf`, leaf);
	}
	deleteResourceLeaf(id: number): Observable<boolean> {
		return this.httpDelete<boolean>(`resourceLeaf/${id}`);
	}

	uploadPdf(event: any) {
		const fileList: FileList = event.target.files;
		if (fileList.length > 0) {
			const file: File = fileList[0];
			const formData: FormData = new FormData();
			formData.append('file', file, file.name);
			return this.httpPost<any>(`resourceLeaf/upload`, formData);
		} else {
			console.log('No file found.');
		}
	}

	moveContentToIndex(type: string, parentId: number, movingId: number, toIndex: number): Observable<boolean> {
		return this.httpPost<boolean>(`${type}Leaf/move/${parentId}/${movingId}/${toIndex}`, null);
	}

	getMoveData(type: string, id: number): Observable<any[]> {
		return this.httpGet<any[]>(`${type}/move/data/${id}`);
	}

	getChildMoveData(type: string, id: number): Observable<any[]> {
		return this.httpGet<any[]>(`parent/leaf/move/data/${type}/${id}`);
	}

	// Contact leaf
	getContactLeafByParentId(id: number): Observable<ContactLeaf[]> {
		return this.httpGet<ContactLeaf[]>(`contactLeaf/parent/${id}`);
	}
	upsertContactLeaf(leaf: ContactLeaf): Observable<any> {
		return this.httpPost<any>(`contactLeaf`, leaf);
	}
	deleteContactLeaf(id: number, parentId: number): Observable<boolean> {
		return this.httpDelete<boolean>(`contactLeaf/${id}/${parentId}`);
	}

	uploadImage(event: any) {
		const fileList: FileList = event.target.files;
		if (fileList.length > 0) {
			const file: File = fileList[0];
			const formData: FormData = new FormData();
			formData.append('file', file, file.name);
			return this.httpPost<any>(`contactLeaf/upload`, formData);
		} else {
			console.log('No file found.');
		}
	}
	// Event Leaf
	getEventLeafByParentId(id: number): Observable<EventLeaf[]> {
		return this.httpGet<EventLeaf[]>(`eventLeaf/parent/${id}`);
	}
	upsertEventLeaf(leaf: EventLeaf): Observable<EventLeaf> {
		return this.httpPost<EventLeaf>(`eventLeaf`, leaf);
	}
	deleteEventLeaf(id: number): Observable<boolean> {
		return this.httpDelete<boolean>(`eventLeaf/${id}`);
	}

	// Html Leaf
	getHtmlLeafByParentId(id: number): Observable<HtmlLeaf[]> {
		return this.httpGet<HtmlLeaf[]>(`htmlLeaf/parent/${id}`);
	}
	upsertHtmlLeaf(leaf: HtmlLeaf): Observable<HtmlLeaf> {
		return this.httpPost<HtmlLeaf>(`htmlLeaf`, leaf);
	}
	deleteHtmlLeaf(id: number): Observable<boolean> {
		return this.httpDelete<boolean>(`htmlLeaf/${id}`);
	}

	// resource/meta calls
	getImages(): Observable<ImageData[]> {
		return this.httpGet<ImageData[]>(`image`);
	}
	getEntityTypes(): Observable<EntityType[]> {
		return this.httpGet<EntityType[]>(`entityType`);
	}
	getChildDisplayTypes(): Observable<ChildDisplayType[]> {
		return this.httpGet<ChildDisplayType[]>(`childDisplayType`);
	}

	// App Info
	getAppInfo(): Observable<Info> {
		return this.httpGet<Info>('appinfo');
	}
	upsertAppInfo(data: Info): Observable<Info> {
		return this.httpPost<Info>(`appinfo`, data);
	}

	// User
	getUsers(): Observable<User[]> {
		return this.httpGet<User[]>('user');
	}
	upsertUser(user: User): Observable<number> {
		return this.httpPost<number>('user', user);
	}
	deleteUser(id: number): Observable<boolean> {
		return this.httpDelete<boolean>(`user/${id}`);
	}
	updatePassword(newPassword: string): Observable<number> {
		return this.httpPost<number>('user/updatePassword', { password: newPassword });
	}

	// Auth
	login(user: LoginRequest): any {
		return this.httpPost(
			`auth/login/${this.projectId}`, user,
		);
	}

	verifyGuidId(guidId: string): any {
		return this.httpGet(`auth/check-reset-guid/${this.projectId}/${guidId}`);
	}

	securityUpdatePassword(guidId: string, user: LoginRequest): any {
		return this.httpPost<boolean>(`auth/reset-password/${this.projectId}/${guidId}`, user);
	}

	forgotPassword(email: string): any {
		return this.httpPost('auth/forgot-password/2', { email: email });
	}

	async saveToken(user: any, token: string) {
		this.token = token;
		localStorage.setItem(
			'currentUser',
			JSON.stringify({
				token,
				role: user.role,
				email: user.email,
			}),
		);
	}

	getCurrentUserEmailOrNull(): string {
		const user = localStorage.getItem('currentUser');
		if (user != null && user.length > 0 && user !== 'null') {
			const currentUser = JSON.parse(user);
			return currentUser.email;
		}
		return null;
	}

	logout(): void {
		// clear token remove user from local storage to log user out
		this.token = null;
		localStorage.setItem('currentUser', null);
	}

	getTimeFromUtcDate(utcDate: Date): Date {
		const st = utcDate == null ? null : new Date(utcDate);
		return (st == null)
			? null
			: new Date(Date.UTC(st.getFullYear(), st.getMonth(), st.getDate(), st.getHours(), st.getMinutes(), 0, 0));
	}

	convertDateToUTC(date: Date): Date {
		return date == null ? null : new Date(
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate(),
			date.getUTCHours(),
			date.getUTCMinutes(),
			date.getUTCSeconds());
	}
}
