import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { BasicLeaf } from 'src/app/models/basicLeaf';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'pm-basic-leaf-modal',
	templateUrl: './basic-leaf-modal.component.html',
	styleUrls: ['./basic-leaf-modal.component.scss', '../styles.scss'],
})

export class BasicLeafModalComponent implements OnInit {

	@Input() item: BasicLeaf;
	@Input() pId: number;
	@Input() eTypeId: number;
	@Output() append: EventEmitter<BasicLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();

	basicLeafForm: FormGroup;
	showEdit: boolean;
	saveTry: boolean;
	pen = faPen;
	plus = faPlus;
	createdDate: Date;
	lastUpdated: Date;

	get displayOfChild() { return this.basicLeafForm.get('displayOfChild'); }
	get valueOfChild() { return this.basicLeafForm.get('valueOfChild'); }
	get isHidden() { return this.basicLeafForm.get('isHidden'); }

	constructor(
		private modalService: NgbModal,
		private dService: DataService,
		private formbuilder: FormBuilder,
	) { }

	ngOnInit(): void {
		this.basicLeafForm = this.formbuilder.group({
			displayOfChild: [null, [Validators.required, Validators.maxLength(24)]],
			valueOfChild: [null, [Validators.required]],
			isHidden: [false],
		});
		this.showEdit = this.item != null;
	}

	open(content: any) {
		if (this.showEdit) {
			this.createdDate = this.dService.getTimeFromUtcDate(this.item.dateCreated);
			this.lastUpdated = this.dService.getTimeFromUtcDate(this.item.dateLastUpdated);
		}
		this.basicLeafForm.reset();
		this.saveTry = false;
		this.displayOfChild.setValue(this.item?.display);
		this.valueOfChild.setValue(this.item?.value);
		this.isHidden.setValue(this.item?.isHidden);
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
	}

	createChild() {
		if (this.basicLeafForm.invalid) {
			this.saveTry = true;
			return;
		}
		const newItem = new BasicLeaf(
			this.pId,
			this.eTypeId,
			this.displayOfChild.value,
			this.valueOfChild.value,
			this.isHidden.value,
		);
		this.save(newItem);
	}

	editChild() {
		if (this.basicLeafForm.invalid) {
			this.saveTry = true;
			return;
		}
		const newItem = Object.assign({}, this.item);
		newItem.display = this.displayOfChild.value;
		newItem.value = this.valueOfChild.value;
		newItem.isHidden = this.isHidden.value;
		this.save(newItem);
	}

	save(item: BasicLeaf) {
		const newItem = item.id === 0;
		this.dService.upsertBasicLeaf(item).subscribe(newLeaf => {
			alert(`Content has been ${newItem ? 'created' : 'updated'}`);
			if (newItem) {
				this.append.emit(newLeaf);
			} else {
				this.item.display = newLeaf.display;
				this.item.value = newLeaf.value;
				this.item.lastUpdateBy = newLeaf.lastUpdateBy;
				this.item.isHidden = newLeaf.isHidden;
				this.item.dateCreated = newLeaf.dateCreated;
				this.item.dateLastUpdated = newLeaf.dateLastUpdated;
			}
			this.modalService.dismissAll(BasicLeafModalComponent);
		});
	}

	cancelBasic() {
		this.modalService.dismissAll(BasicLeafModalComponent);
	}

	deleteChild() {
		const id = this.item.id;
		if (confirm('Are you sure you want to delete?')) {
			this.dService.deleteBasicLeaf(id).subscribe(_ => {
				this.modalService.dismissAll(BasicLeafModalComponent);
				alert('Content has been deleted');
				this.delete.emit(id);
			}, _ => {
				alert('Error trying to delete content');
			});
		}
	}
}
