<pm-shared-header [title]='title' [header]='header' [resourceUrl]='resourceUrl'></pm-shared-header>
<div class="container" *ngIf="!errorMessage">
	<div class="container">
		<div class="row d-flex justify-space-between pl-0 pl-md-3 pr-4">
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col">
						<b>
							<h4 class="m-0">{{ header }}</h4>
						</b>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<a [routerLink]='["/home"]' class="breadcrums">Dashboard</a>
						<span class="breadcrums">{{ " / " + header }}</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end pt-2 pt-md-0">
				<pm-parent-form-modal (append)="appendPage($event)" (delete)="deletePage($event)">
				</pm-parent-form-modal>
			</div>
		</div>
	</div>

	<!-- Drag n drop logic based off of https://stackblitz.com/edit/angular-dyz1eb?file=src%2Findex.html -->
	<div cdkDropListGroup class="drag-grid-container justify-content-center">
		<div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate"
			(cdkDropListDropped)="dropListDropped($event)">
		</div>
		<div cdkDropList *ngFor="let p of dataList" [cdkDropListEnterPredicate]="dropListEnterPredicate"
			(cdkDropListDropped)="dropListDropped($event)">
			<div cdkDrag (cdkDragMoved)="dragMoved($event, p.id)" class="card text-center m-3 float drag-grid-box">
				<pm-parent-view-grid [item]='p' (append)="appendPage($event)" (click)="check(p)" (delete)="deletePage($event)">
				</pm-parent-view-grid>
			</div>
		</div>
	</div>
	<!-- <div cdkDropListGroup
		class="drag-grid-container justify-content-center">
		<div cdkDropList
			[cdkDropListEnterPredicate]="dropListEnterPredicate"
			(cdkDropListDropped)="dropListDropped($event)">
		</div>
		<div cdkDropList *ngFor="let p of dataList"
			[cdkDropListEnterPredicate]="dropListEnterPredicate"
			(cdkDropListDropped)="dropListDropped($event)">
			<div cdkDrag (cdkDragMoved)="dragMoved($event, p.id)" class="card bg-light text-center m-3 drag-grid-box">
				<pm-parent-view-grid [item]='p'
					(append)="appendPage($event)" (delete)="deletePage($event)"></pm-parent-view-grid>
			</div>
		</div>
	</div> -->
</div>
<div class="container" *ngIf="errorMessage">
	<div class="row">
		<h5 class="col-12 text-center">
			{{errorMessage}}
		</h5>
	</div>
</div>
