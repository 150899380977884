<div class="border border-dark bg-light container-fluid pb-2" style="width:100%">
	<div class="row bg-primary">
		<h4 class="mx-auto py-2 text-white c-default">{{item.title}}</h4>
	</div>
	<div class="row mt-1">
		<div class="col-12 col-lg-3 d-flex">
			<div class="container-fluid">
				<div class="row">
					<b>Hosts</b>
				</div>
				<div class="row my-1" *ngIf="item.host1Name">
					<div class="col-9 col-xlg-10">
						<div *ngIf="item.host1Name">
							<p class="card-text">
								<b>Name:</b><br/>{{item.host1Name}}<br/>
								<b>Title:</b><br/>{{item.host1Title}}
							</p>
						</div>
					</div>
					<div class="col-3 col-xlg-2">
						<img class="d-block float-right" src="{{getImageUrl(item.host1ImageUrl)}}" alt="image of person" width="45px"/>
					</div>
				</div>
				<div class="row my-1" *ngIf="item.host2Name">
					<div class="col-9 col-xlg-10">
						<div *ngIf="item.host2Name">
							<p class="card-text">
								<b>Name:</b><br/>{{item.host2Name}}<br/>
								<b>Title:</b><br/>{{item.host2Title}}
							</p>
						</div>
					</div>
					<div class="col-3 col-xlg-2">
						<img class="d-block float-right" src="{{getImageUrl(item.host2ImageUrl)}}" alt="image of person" width="45px"/>
					</div>
				</div>
				<div class="row my-1" *ngIf="item.host3Name">
					<div class="col-9 col-xlg-10">
						<div *ngIf="item.host3Name">
							<p class="card-text">
								<b>Name:</b><br/>{{item.host3Name}}<br/>
								<b>Title:</b><br/>{{item.host3Title}}
							</p>
						</div>
					</div>
					<div class="col-3 col-xlg-2">
						<img class="d-block float-right" src="{{getImageUrl(item.host3ImageUrl)}}" alt="image of person" width="45px"/>
					</div>
				</div>
				<div class="row my-1" *ngIf="!item.host1Name && !item.host2Name && !item.host3Name">
					<div class="row mx-auto">
						No hosts provided
					</div>
				</div>
			</div>
				<!-- <div *ngIf="item.host2Name">
					<p class="card-text">
						<b class="mx-2">Name:</b>{{item.host2Name}} <br/>
						<b class="mx-2">Title:</b>{{item.host2Title}} <br/>
					</p>
				</div>
				<div *ngIf="item.host3Name">
					<p class="card-text">
						<b class="mx-2">Name:</b>{{item.host3Name}} <br/>
						<b class="mx-2">Title:</b>{{item.host3Title}} <br/>
					</p>
				</div> -->
		</div><!-- end hosts column container -->
		<div class="col-12 col-md-6 col-lg-3 d-flex flex-column">
			<dl>
				<dt class="text-danger mb-4" *ngIf="hasEnded">Event has ended</dt>
				<dt *ngIf="!hasEnded">Start</dt>
				<dd *ngIf="!hasEnded">{{dateStart | date: 'shortDate'}}</dd>
				<dt>End</dt>
				<dd>{{dateEnd | date: 'shortDate'}}</dd>
				<dt *ngIf="!item.isVirtual">Location</dt>
				<dd *ngIf="!item.isVirtual">{{item.location}}</dd>
				<dt *ngIf="!item.isVirtual">Address</dt>
				<dd *ngIf="!item.isVirtual">{{item.address}}<br/>{{item.city}}, {{item.state}} {{item.zip}}</dd>
			</dl>
		</div>
		<div class="col-12 col-md-6 col-lg-5 d-flex flex-column">
			<dl>
				<dt>Description</dt>
				<dd>{{item.description}}</dd>
				<dt>Link Url</dt>
				<dd>{{item.linkUrl}}</dd>
			</dl>
		</div>
		<div class="col-12 col-lg-1 p-2 d-flex flex-row flex-lg-column justify-content-center align-items-lg-end">
			<pm-event-leaf-modal [item]="item"
				(append)="appendChild($event)"
				(delete)="deleteChild($event)">
			</pm-event-leaf-modal>
			<pm-hidden-btn class="mx-2 mx-lg-0 my-0 my-lg-2"
				[item]="item" [type]="type">
			</pm-hidden-btn>
		</div>
	</div>
	<div class="row" *ngIf="item.lastUpdateBy">
		<small class="col-12 d-flex justify-content-center c-default">
			Last update by: {{item.lastUpdateBy}}
		</small>
	</div>
</div>
