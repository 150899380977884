export class AppStats {
	id: number;
	projectId: number;
	projectName: string;
	googleRating: number;
	googleInstalls: string;
	appleRating: number;
	appleRatingCount: string;
	googleVersion: string;
	appleVersion: string;
	lastRecordDate: Date;
}
