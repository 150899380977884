<pm-shared-header [title]='title' [header]='header' [resourceUrl]='resourceUrl' [visibility]='visibility'>
</pm-shared-header>
<div class="container" *ngIf="!errorMessage">
	<div class="row d-flex justify-space-between pl-0 pl-md-3 pr-4">
		<div class="col-12 col-md-6">
			<app-breadcrumbs [header]="header" [id]="backId" [crumbs]="crumbs" [item]="parentOfParents"></app-breadcrumbs>
		</div>
		<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end pt-2 pt-md-0">
			<pm-parent-form-modal (append)="appendPage($event)"></pm-parent-form-modal>
		</div>
	</div>
	<div cdkDropListGroup
		class="drag-grid-container justify-content-center">
		<div cdkDropList class="gridDropList" [cdkDropListEnterPredicate]="dropListEnterPredicate"
			(cdkDropListDropped)="dropListDropped($event)">
		</div>
		<div cdkDropList class="gridDropList" *ngFor="let p of dataList"
			[cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
			<div cdkDrag (cdkDragMoved)="dragMoved($event, p.id)" class="card text-center m-3 float drag-grid-box">
				<pm-parent-view-grid [item]='p' (append)="appendPage($event)" (delete)="deletePage($event)">
				</pm-parent-view-grid>
			</div>
		</div>
	</div>
<div class="container" *ngIf="errorMessage">
	<div class="row">
		<h3 class="col-12 text-center">
			{{ errorMessage }}
		</h3>
	</div>
</div>
