import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { ContactLeaf, User } from 'src/app/models/_module';
import { environment } from 'src/environments/environment';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { ParentList } from 'src/app/models/parentList';

@Component({
    selector: 'pm-contact-leaf-modal',
    templateUrl: './contact-leaf-modal.component.html',
    styleUrls: ['./contact-leaf-modal.component.scss', '../styles.scss'],
})

export class ContactLeafModalComponent implements OnInit {
    contactForm: FormGroup;
    @Input() eTypeId: number;
    @Input() pId: number;
    @Input() item: ContactLeaf;
    type = 'Contact';
    isEdit: Boolean;
    folderLocationArray: number[] = [];

    fileLabel = 'Upload Image File';
    fileUrl: string;
    @Output() append: EventEmitter<ContactLeaf> = new EventEmitter();
    @Output() delete: EventEmitter<number> = new EventEmitter();
    saveTry: boolean;
    pen = faPen;
    plus = faPlus;
    list: any[];
    createdDate: Date;
    lastUpdated: Date;
    dropdownList = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};
    parentList: ParentList[];

    // retrieve information
    get id() { return this.contactForm.get('id'); }
    get firstName() { return this.contactForm.get('firstName'); }
    get lastName() { return this.contactForm.get('lastName'); }
    get title() { return this.contactForm.get('title'); }
    get department() { return this.contactForm.get('department'); }
    get program() { return this.contactForm.get('program'); }
    get location() { return this.contactForm.get('location'); }
    get phone() { return this.contactForm.get('phone'); }
    get phoneExtension() { return this.contactForm.get('phoneExtension'); }
    get email() { return this.contactForm.get('email'); }
    get imageUrl() { return this.contactForm.get('imageUrl'); }
    get createUser() { return this.contactForm.get('createUser'); }
    get isHidden() { return this.contactForm.get('isHidden'); }
    get folderLocation() { return this.contactForm.get('folderLocation'); }

    constructor(private modalService: NgbModal,
        public dService: DataService,
        private formbuilder: FormBuilder,
        public router: Router,
        public route: ActivatedRoute) { }

    ngOnInit(): void {
        this.parentList = this.item?.parentList;

        this.isEdit = this.item != null;
        // Initialize contact Form & validation
        this.contactForm = this.formbuilder.group({
            firstName: ['', [Validators.required, Validators.maxLength(50)]],
            lastName: ['', [Validators.required, Validators.maxLength(50)]],
            title: ['', [Validators.maxLength(50)]],
            department: ['', [Validators.maxLength(50)]],
            program: ['', [Validators.maxLength(100)]],
            location: ['', [Validators.maxLength(50)]],
            phone: ['', [Validators.maxLength(18)]],
            phoneExtension: ['', [Validators.maxLength(10)]],
            email: ['', [Validators.email, Validators.maxLength(50)]],
            imageUrl: [{ value: '', disabled: true }],
            createUser: [false, [this.HasEmailValidator('email')]],
            isHidden: [false],
            folderLocation: [this.pId],
        });

        let _one = [];
        let _two = [];
        let _selectedList = [];
        for (var key in this.parentList) {
            if (this.parentList.hasOwnProperty(key)) {
                let selectedTitle = this.item?.parentId === this.parentList[key].parentId ? 'Current Folder' : this.parentList[key].title;
                // if (this.item?.parentId === this.parentList[key].parentId) {
                //     title = 'Current Folder'
                //  } else {
                //     title =  this.parentList[key].selectedTitle
                //  }
                var selectedItem = {
                    id: this.parentList[key].parentId,
                    title: selectedTitle
                };

                if (selectedTitle === 'Current Folder') {
                    _one.push(selectedItem)
                } else {
                    _two.push(selectedItem)
                }
                _selectedList = _one.concat(_two);
            }
        }

        const id = this.pId == null ? this.item?.parentId : this.pId;

        if (this.isEdit) {
            this.dService.getMoveData('contactLeaf', id).subscribe(x => {
                this.list = x
                this.dropdownSettings = {
                    idField: 'id',
                    textField: 'title',
                    enableCheckAll: false,
                };
                this.list.unshift({
                    id: id,
                    title: 'Current Folder',
                });
                this.selectedItems = _selectedList;
            });

            this.createdDate = this.dService.getTimeFromUtcDate(this.item.dateCreated);
            this.lastUpdated = this.dService.getTimeFromUtcDate(this.item.dateLastUpdated);
        }
    }

    onItemSelect(item: any) {
        this.folderLocationArray.push(item);
    }

    onItemDeSelect(item: any) {
        const index = this.folderLocationArray.indexOf(item, 0);
        if (index > -1) {
            this.folderLocationArray.splice(index, 1);
        }
    }

    open(content: any) {
        if (this.isEdit) {
            this.createdDate = this.dService.getTimeFromUtcDate(this.item.dateCreated);
            this.lastUpdated = this.dService.getTimeFromUtcDate(this.item.dateLastUpdated);
        }
        this.contactForm.reset();
        this.firstName.setValue(this.item?.firstName);
        this.lastName.setValue(this.item?.lastName);
        this.title.setValue(this.item?.title);
        this.department.setValue(this.item?.department);
        this.program.setValue(this.item?.program);
        this.location.setValue(this.item?.location);
        this.phone.setValue(this.item?.phone);
        this.phoneExtension.setValue(this.item?.phoneExtension);
        this.email.setValue(this.item?.email);
        this.imageUrl.setValue(this.item?.imageUrl);
        this.fileLabel = this.item?.imageUrl?.split('/')?.pop() ?? 'Upload Image';
        this.createUser.setValue(false);
        this.isHidden.setValue(this.item?.isHidden);
        this.folderLocation.setValue(this.item?.parentId ?? this.pId);
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    }

    createContactLeaf() {
        if (this.contactForm.invalid) {
            this.saveTry = true;
            return;
        }
        const newItem: ContactLeaf = {
            id: 0,
            parentId: this.pId,
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            title: this.title.value,
            department: this.department.value,
            program: this.program.value,
            location: this.location.value,
            phone: this.phone.value,
            phoneExtension: this.phoneExtension.value,
            entityTypeId: +this.eTypeId,
            imageUrl: this.contactForm.controls['imageUrl'].value,
            email: this.email.value,
            isHidden: this.isHidden.value,
            createUser: this.createUser.value,
            lastUpdateBy: null,
            dateCreated: null,
            dateLastUpdated: null,
            parentIds: null,
            parentList: []
        };
        this.save(newItem);
    }

    editContactLeaf() {
        if (this.contactForm.invalid) {
            this.saveTry = true;
            return;
        }
        const zeroSymbol = '0';
        const resultArray = this.selectedItems.filter(f => f.id != zeroSymbol).map(({ id }) => id);
        const newItem = Object.assign({}, this.item);
        // const isMove = newItem.parentId !== this.folderLocation.value;
        newItem.firstName = this.firstName.value;
        newItem.lastName = this.lastName.value;
        newItem.title = this.title.value;
        newItem.department = this.department.value;
        newItem.program = this.program.value;
        newItem.location = this.location.value;
        newItem.phone = this.phone.value;
        newItem.phoneExtension = this.phoneExtension.value;
        newItem.email = this.email.value;
        newItem.imageUrl = this.imageUrl.value;
        newItem.isHidden = this.isHidden.value;
        newItem.parentId = this.item?.parentId;
        // newItem.parentId = isMove ? this.folderLocation.value : newItem.parentId;
        newItem.parentIds = resultArray.toString();
        this.save(newItem);
    }

    save(item: ContactLeaf) {
        this.dService.upsertContactLeaf(item).subscribe(resp => {
            const { contactLeaf, message } = resp;
            if (message != null) {
                alert(`Contact created. ${message}.`);
            } else {
                alert(`Contact successfully ${this.isEdit ? 'update' : 'created'}`);
                location.reload();
            }
            if (!this.isEdit) {
                if (this.pId === this.folderLocation.value) {
                    this.append.emit(contactLeaf);
                }
            } else {
                // set display and value properties of item to new values
                this.item.firstName = contactLeaf.firstName;
                this.item.lastName = contactLeaf.lastName;
                this.item.title = contactLeaf.title;
                this.item.department = contactLeaf.department;
                this.item.program = contactLeaf.program;
                this.item.location = contactLeaf.location;
                this.item.phone = contactLeaf.phone;
                this.item.phoneExtension = contactLeaf.phoneExtension;
                this.item.email = contactLeaf.email;
                this.item.imageUrl = contactLeaf.imageUrl;
                this.item.lastUpdateBy = contactLeaf.lastUpdateBy;
                this.item.isHidden = contactLeaf.isHidden;
                this.item.dateCreated = contactLeaf.dateCreated;
                this.item.dateLastUpdated = contactLeaf.dateLastUpdated;
                // this.item.parentId = this.folderLocation.value;
                this.item.parentId = this.item.id;
                this.item.parentIds = this.folderLocation.value.id;
                if (this.item.parentId !== this.pId) {
                    this.delete.emit(this.item.id);
                }
            }
            // refresh parent after response
            this.modalService.dismissAll(ContactLeafModalComponent);
        }, exc => {
            alert('Contact was not created. \nError: ' + exc.error);
        });
    }

    cancelContact() {
        this.modalService.dismissAll(ContactLeafModalComponent);
        this.folderLocationArray = [];
    }

    deleteContactLeaf() {
        const targetId = this.item.id;
        const pId = this.item.parentId;
        if (confirm('Are you sure you want to delete?')) {
            // pass item to data service, to be deleted
            this.dService.deleteContactLeaf(targetId, pId).subscribe(_ => {
                // close modal
                this.modalService.dismissAll(ContactLeafModalComponent);
                alert('Contact was successful deleted');
                this.delete.emit(targetId);
            }, _ => {
                alert('Error trying to delete contact');
            });
        }
    }

    fileChanged(event: any) {
        this.dService.uploadImage(event)
            .subscribe(
                resp => {
                    window.alert('Upload Successful');
                    this.fileLabel = resp.fileName;
                    this.fileUrl = resp.url;
                    this.imageUrl.setValue(this.fileUrl);
                },
                ex => window.alert(ex.error),
            );
    }

    createNewUser() {
        const u: User = {
            id: 0,
            email: this.item.email,
            projectId: environment.projectId,
            password: null,
            projectName: '',
        };
        this.dService.upsertUser(u)
            .subscribe(
                () => {
                    window.alert('User created, an email was sent to the user.');
                },
                ex => window.alert(ex.error),
            );
    }

    HasEmailValidator(emailInput: string) {
        let createUserControl: FormControl;
        let emailControl: FormControl;

        return (control: FormControl) => {
            if (!control.parent) {
                return null;
            }
            if (!createUserControl) {
                createUserControl = control;
                emailControl = control.parent.get(emailInput) as FormControl;
                // whenever display type changes, update validity
                emailControl.valueChanges.subscribe(() => {
                    createUserControl.updateValueAndValidity();
                });
            }
            if (createUserControl.value === true && emailControl.value === '') {
                return {
                    emailMissing: true,
                };
            }
            return null;
        };
    }
}
