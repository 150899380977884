<pm-shared-header [title]='title' [header]='header' [resourceUrl]='resourceUrl'></pm-shared-header>
<div *ngIf="!errorMessage" class="container pb-3">
	<div class="row">
		<div class="col-12 px-5 px-sm-0 col-sm-6 col-md-4">
			<a routerLink="/users">
				<div class="card text-white m-2">
					<div class="card-header bg-primary">
						<h5>Users</h5>
					</div>
					<img src="../../../assets/images/group.svg" alt="group icon" class="card-img-bottom p-3">
				</div>
			</a>
		</div>
		<div class="col-12 px-5 px-sm-0 col-sm-6 col-md-4">
			<a routerLink="/info">
				<div class="card text-white m-2">
					<div class="card-header bg-primary">
						<h5>App Info</h5>
					</div>
					<img src="../../../assets/images/write.svg" alt="info icon" class="card-img-bottom p-3">
				</div>
			</a>
		</div>
		<div class="col-12 px-5 px-sm-0 col-sm-6 col-md-4">
			<a routerLink="/dashboard">
				<div class="card text-white m-2">
					<div class="card-header bg-primary">
						<h5>App Toolkit</h5>
					</div>
					<img src="../../../assets/images/mobile.svg" alt="work icon" class="card-img-bottom p-3">
				</div>
			</a>
		</div>
		<div class="col-12 px-5 px-sm-0 col-sm-6 col-md-12 d-flex flex-column align-items-start align-items-md-center justify-content-center">
			<div class="mt-3 mt-sm-0 mx-5">
				<img class="d-inline mr-2" style="height:24px; width:24px;" src="../../../assets/imgs/android-full.svg"><span>v.{{appStats.googleVersion}}</span><br>
				Installs: {{appStats.googleInstalls}}<br>
				<pm-star-rating [rating]="appStats.googleRating" ratingText="Rating"></pm-star-rating>
			</div>
			<div class="mt-3 mx-5">
				<img class="d-inline mr-2" style="height:24px; width:24px;" src="../../../assets/imgs/apple.svg"><span>v.{{appStats.appleVersion}}</span><br>
				Ratings: {{appStats.appleRatingCount}}<br>
				<pm-star-rating [rating]="appStats.appleRating" ratingText="Rating"></pm-star-rating>
			</div>
		</div>
	</div>
</div>
<div class="container" *ngIf="errorMessage">
	<div class="row">
		<h3 class="col-12 text-center">{{errorMessage}}</h3>
	</div>
</div>
