export class ResourceLeaf {
	id: number;
	display: string;
	value: string;
	pdfUrl: string;
	linkUrl: string;
	parentId: number;
	parentTitle: string;
	entityTypeId: number;
	entityTypeName: string;
	isHidden: boolean;
	lastUpdateBy: string;
	fileUploadDate: Date;
	dateCreated: Date;
	dateLastUpdated: Date;
}
