import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
	selector: 'pm-forgot-password-modal',
	templateUrl: './forgot-password-modal.component.html',
	styleUrls: ['./forgot-password-modal.component.scss'],
})
export class ForgotPasswordModalComponent implements OnInit {
	passwordForm: FormGroup;
	saveTry = false;
		// retrieve user email
	get email() { return this.passwordForm.get('email'); }
	constructor(private service: DataService,
		private modalService: NgbModal,
		private formbuilder: FormBuilder) { }
	ngOnInit(): void {
		// Init signInForm & validate
		this.passwordForm = this.formbuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}
	recoverPassword(email) {
		if (this.passwordForm.invalid) {
			this.saveTry = true;
		} else {
			this.service.forgotPassword(email).subscribe(_ => {
				alert('You will receive an email with a password reset instructions.');
				this.modalService.dismissAll(ForgotPasswordModalComponent);
				this.passwordForm.reset();
			}, () => {
				alert('The email provided was not the system.');
			});
		}
	}
	open(content: any) {
	 	// open modal
	 	this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
	}
}
