import { ViewChild } from '@angular/core';
import { CdkDropListGroup, CdkDropList, moveItemInArray, CdkDragMove, CdkDrag } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';

export abstract class DragNDropComponent<T> {
	@ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
	@ViewChild(CdkDropList) placeholderList: CdkDropList;
	target: CdkDropList;
	targetIndex: number;
	source: CdkDropList;
	sourceIndex: number;
	dragIndex: number;
	activeContainer: any;
	movingId: number;
	id: number;

	dataList: T[] = [];

	constructor(private viewportRuler: ViewportRuler) {
		this.target = null;
		this.source = null;
	}

	setPhElement() {
		if (this.placeholderList == null) {
			return;
		}
		const phElement = this.placeholderList.element.nativeElement;
		phElement.style.display = 'none';
		phElement.parentElement.removeChild(phElement);
	}

	// Called by tile being moved
	dragMoved(e: CdkDragMove, i: number) {
		this.movingId = i;
		const point = this.getPointerPositionOnPage(e.event);
		this.listGroup._items.forEach(dropList => {
			if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
				this.activeContainer = dropList;
				return;
			}
		});
	}
	abstract actionMove(id: number, movingId: number, targetIndex: number): void;

	dropListDropped(_: any) {
		if (!this.target) {
			return;
		}

		const phElement = this.placeholderList.element.nativeElement;
		const parent = phElement.parentElement;

		phElement.style.display = 'none';

		parent.removeChild(phElement);
		parent.appendChild(phElement);
		parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

		this.target = null;
		this.source = null;

		if (this.sourceIndex !== this.targetIndex) {
			moveItemInArray(this.dataList, this.sourceIndex, this.targetIndex);
		}

		this.actionMove(this.id, this.movingId, this.targetIndex);
	}

	dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
		if (drop === this.placeholderList) {
			return true;
		}

		if (drop !== this.activeContainer) {
			return false;
		}

		const phElement = this.placeholderList.element.nativeElement;
		const sourceElement = drag.dropContainer.element.nativeElement;
		const dropElement = drop.element.nativeElement;

		const dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
		const dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

		if (!this.source) {
			this.sourceIndex = dragIndex;
			this.source = drag.dropContainer;

			phElement.style.width = sourceElement.clientWidth + 'px';
			phElement.style.height = sourceElement.clientHeight + 'px';

			sourceElement.parentElement.removeChild(sourceElement);
		}

		this.targetIndex = dropIndex;
		this.target = drop;

		phElement.style.display = '';
		dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
			? dropElement.nextSibling : dropElement));

		this.placeholderList.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
		return false;
	}

	/** Determines the point of the page that was touched by the user. */
	getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
		// `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
		const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
		const scrollPosition = this.viewportRuler.getViewportScrollPosition();

		return {
			x: point.pageX - scrollPosition.left,
			y: point.pageY - scrollPosition.top,
		};
	}
}
export function __indexOf(collection, node) {
	return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
export function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
	return event.type.startsWith('touch');
}

export function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
	const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
	return y >= top && y <= bottom && x >= left && x <= right;
}
