<pm-shared-header [title]='title' [header]='header' [resourceUrl]='resourceUrl'></pm-shared-header>
<div class="container">
    <div class="row d-flex justify-space-between pl-0">
		<div class="col-12 col-md-6 pl-0">
			<div class="row">
				<div class="col">
					<b>
						<h4 class="m-0">{{ header }}</h4>
					</b>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<a [routerLink]='["/home"]' class="breadcrums">Dashboard</a>
					<span class="breadcrums">{{ " / " + header }}</span>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end pt-2 pt-md-0 pr-0">
			<pm-info-modal [appInfo]="appInfo" (refreshPage)="refreshPage()"></pm-info-modal>
		</div>
	</div>
    <div class="row pt-3">
        <div class="col d-flex flex-column align-items-center">
                <div class="text-left" [innerHtml]="htmlAboutUs"></div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-center mt-5">
                <div class="socialDistance">
                    <a *ngIf="appInfo?.facebook != null" href="https://www.facebook.com/{{appInfo?.facebook}}" target="_blank">
                        <img src="../../../assets/imgs/facebook.png" alt="facebook">
										</a>
										<img *ngIf="appInfo?.facebook == null" src="../../../assets/imgs/facebook_disabled.png" alt="facebook disabled">
                    <p>/{{appInfo?.facebook == null ? "-" : appInfo.facebook}}</p>
                </div>

                <div class="socialDistance">
                    <a *ngIf="appInfo?.instagram != null" href="https://www.instagram.com/{{appInfo?.instagram}}" target="_blank">
                        <img src="../../../assets/imgs/instagram.png" alt="instagram">
										</a>
										<img *ngIf="appInfo?.instagram == null" src="../../../assets/imgs/instagram_disabled.png" alt="instagram">
                    <p>/{{appInfo?.instagram == null ? "-" : appInfo.instagram}}</p>
                </div>

                <div class="socialDistance">
                    <a *ngIf="appInfo?.twitter != null" href="https://www.twitter.com/{{appInfo?.twitter}}" target="_blank">
                        <img src="../../../assets/imgs/twitter.png" alt="twitter">
										</a>
										<img *ngIf="appInfo?.twitter == null" src="../../../assets/imgs/twitter_disabled.png" alt="twitter">
                    <p>/{{appInfo?.twitter == null ? "-" : appInfo.twitter}}</p>
                </div>

                <div class="socialDistance">
                    <a *ngIf="appInfo?.contactEmail != null" href="mailto:{{appInfo?.contactEmail}}">
											<img src="../../../assets/imgs/email.png" alt="email">
										</a>
										<img *ngIf="appInfo?.contactEmail == null" src="../../../assets/imgs/email_disabled.png" alt="email">
                    <p>{{appInfo?.contactEmail == null ? "-" : appInfo.contactEmail}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
