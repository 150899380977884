import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/_modules';
import { UsersModalComponent } from '../users-modal/users-modal.component';
import { Parent } from 'src/app/models/parent';

@Component({
	selector: 'pm-move-content-modal',
	templateUrl: './move-content-modal.component.html',
	styleUrls: ['./move-content-modal.component.scss', '../styles.scss'],
})
export class MoveContentModalComponent implements OnInit {
	userId: number;
	form: FormGroup;
	@Input() item: any;
	@Input() type: string;
	@Input() title: string;
	@Output() moved: EventEmitter<any> = new EventEmitter();
	saveTry: boolean;
	list: any[] = [];
	urlType = '';

	get moveToFolder() { return this.form.get('moveToFolder'); }

	constructor(
		private modalService: NgbModal,
		private formbuilder: FormBuilder,
		private dService: DataService,
	) { }

	ngOnInit(): void {
		this.form = this.formbuilder.group({
			moveToFolder: ['', [Validators.required]],
		});
	}

	open(content: any) {
		if (this.type === 'Folder') {
			this.urlType = 'parent';
		} else if (this.type === 'Contact') {
			this.urlType = 'contactLeaf';
		} else {
			alert('Moving this type is not supported');
			return;
		}
		this.dService.getMoveData(this.urlType, this.item.id).subscribe(x => {
			this.list = x;
			if (this.form.dirty) {
				this.form.reset();
			}
			this.saveTry = false;
			this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
		});

	}
	close() {
		this.modalService.dismissAll();
	}
	moveContent() {
		if (this.form.invalid) {
			this.saveTry = true;
			return;
		}
		const parentId = +this.moveToFolder.value;
		this.dService.moveToParentId(this.urlType, this.item, parentId).subscribe(_ => {
			this.item.parentId = parentId;
			this.item.orderIndex = null;
			this.item.imageId = null;
			alert(`${this.type} has been moved`);
			this.moved.emit(this.item.id);
			this.modalService.dismissAll(UsersModalComponent);
		});
	}
}
