import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faBorderAll, faEye, faEyeSlash, faList, faLaptopCode, faPen, faFolderOpen, faUserSlash, faUser} from '@fortawesome/free-solid-svg-icons';
import { Parent } from 'src/app/models/parent';

@Component({
	selector: 'pm-parent-view-grid',
	templateUrl: './parent-view-grid.component.html',
	styleUrls: ['./parent-view-grid.component.scss'],
})
export class ParentViewGridComponent implements OnInit {
	@Input() item: Parent;
	@Output() append: EventEmitter<Parent> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	isDashboard: boolean;
	type = 'Folder';
	grid = faBorderAll;
	eye = faEye;
	eyeSlash = faEyeSlash;
	list = faList;
	page = faLaptopCode;
	pen = faPen;
	folder = faFolderOpen;
	photoUnavailable = faUserSlash;
	constructor() { }

	ngOnInit(): void {
		this.isDashboard = this.item?.parentId === 0;
	}

	appendPage(item: Parent) {
		this.append.emit(item);
	}

	deletePage(id: number) {
		this.delete.emit(id);
	}
}
