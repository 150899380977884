<div class="card bg-light text-center m-4" style="width: 18rem;">
	<h5 class="card-header bg-primary text-white">
		{{ item.display }}
	</h5>
	<div class="card-body text-center">
		<div>
			<p class="card-text">
				{{ item.value }}
			</p>
		</div>
		<div class="d-flex justify-content-around">
			<pm-basic-leaf-modal
				[item]="item"
				(append)="appendChild($event)"
				(delete)="deleteChild($event)"
			></pm-basic-leaf-modal>
			<pm-hidden-btn [item]="item" [type]="type"></pm-hidden-btn>
		</div>
	</div>
</div>
