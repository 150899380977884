export class BasicLeaf {
	id: number;
	display: string;
	value: string;
	parentId: number;
	parentTitle: string = null;
	entityTypeId: number;
	entityTypeName: string = null;
	isHidden = true;
	lastUpdateBy: string = null;
	dateCreated: Date = null;
	dateLastUpdated: Date = null;

	constructor(parentId: number, entityTypeId: number, display: string, value: string, isHidden: boolean) {
		this.id = 0;
		this.display = display;
		this.value = value;
		this.parentId = parentId;
		this.entityTypeId = entityTypeId;
		this.isHidden = isHidden;
	}
}
