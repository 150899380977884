import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewPasswordModalComponent } from 'src/app/modals/_module';


@Component({
	selector: 'pm-shared-header',
	templateUrl: './shared-header.component.html',
	styleUrls: ['./shared-header.component.scss'],
})
export class SharedHeaderComponent implements OnInit {

	@Input() title: string;
	@Input() header: string;
	@Input() resourceUrl: string;
	@Input() visibility: string;

	isExpanded = false;
	constructor(private dService: DataService, private router: Router, private modalService: NgbModal) { }

	ngOnInit(): void { }
	logout() {
		this.dService.logout();
		this.router.navigate(['sign-in']);
	}
	toggle() {
		this.isExpanded = !this.isExpanded;
	}
	collapse() {
		this.isExpanded = false;
	}
	changePassword() {
		this.modalService.open(NewPasswordModalComponent);
	}
}
