<div class="row">
	<div class="col">
		<b>{{breadcrumbHeader? breadcrumbHeader: header}}</b>
		<span class="pl-4">
			<pm-parent-form-modal [p]="item" [type]="type" [noMove]="'1'" (updatedTitle)="title($event)"></pm-parent-form-modal>
		</span>
	</div>
</div>
<div class="row">
	<div class="col breadcrums" aria-label="breadcrumb">
		<ol class="breadcrumb p-0">
			<li class="breadcrumb-item p-0"><a [routerLink]='["/home"]'>Dashboard</a></li>
			<li class="breadcrumb-item p-0"><a [routerLink]='["/dashboard"]'>App Toolkit</a></li>
			<li *ngFor="let c of crumbs | slice: 0:crumbs?.length -1" class="breadcrumb-item p-0">
				<a [routerLink]='["/parent", c.id]'>{{ c.title }}</a>
			</li>
			<li *ngIf='crumbs' class="breadcrumb-item active p-0" aria-current="page">
				{{ crumbs[crumbs.length-1].title }}
			</li>
		</ol>
	</div>
</div>
