<div class="card float" style="width: 18rem;">
	<!-- <h5 class="card-header bg-primary text-white text-center">
		{{ item.firstName }} {{ item.lastName }}
	</h5> -->
	<div class="card-body text-center" [ngClass]="{'pb-1': item.lastUpdateBy}">
		<img class="d-block m-auto" src="{{(isImageUrlEmpty()) ? '../../../assets/imgs/no_photo.jpg' : item.imageUrl}}" alt="image of person" height="120px"/>
		<p class="mt-2"><b>{{ item.firstName }}&nbsp;{{ item.lastName }}</b></p>
		<p class="card-text text-left mt-2"><b class="mr-1">Job Title:</b>{{ item.title }}</p>
		<p class="card-text text-left"><b class="mr-1">Department:</b>{{ item.department }}</p>
		<p class="card-text text-left">
			<b class="mr-1">Phone:</b>{{ item.phone }} <span *ngIf="hasPhoneExtention()"><b
					class="mr-1">Ext:</b>{{ item.phoneExtension }}</span>
		</p>
		<p class="card-text text-left"><b class="mr-1">Email:</b>{{ item.email }}</p>
		<div class="row mt-3 pb-2">
			<div class="col-6 d-flex justify-content-start">
				<fa-icon *ngIf="!item.isHidden" class="fa-xs greyIcon" [icon]="eye"></fa-icon>
				<fa-icon *ngIf="item.isHidden" class="fa-xs greyIcon" [icon]="eyeSlash"></fa-icon>
				<span class="iconText pl-1 m-0">{{ item.isHidden? "Hidden" : "Visible" }}</span>
			</div>
			<div class="col-6 d-flex justify-content-end">
				<pm-contact-leaf-modal [pId]="item.parentId" [item]="item" (append)="appendChild($event)" (delete)="deleteChild($event)">
				</pm-contact-leaf-modal>
			</div>
		</div>
	</div>
</div>
