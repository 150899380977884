import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { HtmlLeaf } from 'src/app/models/html-leaf';

@Component({
	selector: 'pm-html-view-grid',
	templateUrl: './html-view-grid.component.html',
	styleUrls: ['./html-view-grid.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HtmlViewGridComponent implements OnInit {
	@Input() item: HtmlLeaf;
	@Output() append: EventEmitter<HtmlLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Html';
	eye = faEye;
	eyeSlash = faEyeSlash;
	@ViewChild('editorTxt', { static: true }) editorTxt: ElementRef;
	constructor() { }

	ngOnInit(): void {
		this.setInnerHtml();
	}

	setInnerHtml() {
		this.editorTxt.nativeElement.innerHTML = this.item.value;
	}

	appendChild(item: HtmlLeaf) {
		this.append.emit(item);
		this.setInnerHtml();
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}
}
