<!-- <a [routerLink]="item.entityTypeName === 'Parent'
		? ['/parent',item.id]
		: ['/child',item.id]">
	<h5 class="card-header bg-primary text-white text-center"> {{ item.title }}</h5>
</a> -->
<div class="card-body text-left text-dark p-0" [ngClass]="{'pb-1': item.lastUpdateBy}">
	<div class="container">
		<div class="row">
			<div *ngIf="item.imageName" class="col-2 pl-1 pr-0 pt-1">
				<img  height="50" width="50" src="../../../assets/images/{{item.imageName}}.svg"
					alt="{{item.imageName}}">
			</div>
			<div class="col-7 pt-2">
				<div class="row">
					<div class="col-12 pl-2">
						<b>
							<p class="card-text pl-1">
								{{ item.title? item.title : "N/a" }}
							</p>
						</b>
					</div>
				</div>
				<div class="row">
					<div class="col-12 d-flex align-items-start pl-2">
						<p class="type m-0 pb-4 pl-1"> {{ (item.entityTypeName === 'Parent') ? 'Folder' : item.entityTypeName }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-2 px-0 ml-3">
				<fa-icon *ngIf="item.childDisplayTypeName === 'Grid'" class="fa-xs greyIcon" [icon]="grid"></fa-icon>
				<fa-icon *ngIf="item.childDisplayTypeName === 'List'" class="fa-xs greyIcon" [icon]="list"></fa-icon>
				<fa-icon *ngIf="item.childDisplayTypeName === 'Page'" class="fa-xs greyIcon" [icon]="page"></fa-icon>
				<span class="iconText pl-1 m-0">{{ item.childDisplayTypeName }}</span>
			</div>
			<div class="col-3 px-0">
				<fa-icon *ngIf="!item.isHidden" class="fa-xs greyIcon" [icon]="eye"></fa-icon>
				<fa-icon *ngIf="item.isHidden" class="fa-xs greyIcon" [icon]="eyeSlash"></fa-icon>
				<span class="iconText pl-1 m-0">{{ item.isHidden? "Hidden" : "Visible" }}</span>
			</div>
			<div class="col-6 d-flex justify-content-end mb-3">
				<pm-parent-form-modal class="px-2" [p]="item" [type]='type' (append)="appendPage($event)"
					(delete)="deletePage($event)">
				</pm-parent-form-modal>
				<button class="btn btn-background px-1 py-0"
					[routerLink]="item.entityTypeName === 'Parent'? ['/parent',item.id] : ['/child',item.id]">
					<fa-icon class="blueIcon" [icon]="folder"></fa-icon>
				</button>
			</div>
		</div>
	</div>
</div>
