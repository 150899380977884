import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContactLeaf } from 'src/app/models/_module';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'pm-contact-view-grid',
	templateUrl: './contact-view-grid.component.html',
	styleUrls: ['./contact-view-grid.component.scss'],
})
export class ContactViewGridComponent implements OnInit {
	@Input() item: ContactLeaf;
	@Output() append: EventEmitter<ContactLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Contact';
	eye = faEye;
	eyeSlash = faEyeSlash;
	
	constructor() { }

	ngOnInit(): void {
	}

	isImageUrlEmpty(): boolean {
		return this.item.imageUrl == null || this.item.imageUrl === '';
	}

	hasPhoneExtention(): boolean {
		return this.item.phoneExtension != null && this.item.phoneExtension !== '';
	}

	appendChild(item: ContactLeaf) {
		this.append.emit(item);
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}
}
