import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { DataService } from 'src/app/services/_modules';

@Component({
	selector: 'pm-new-password-modal',
	templateUrl: './new-password-modal.component.html',
	styleUrls: ['./new-password-modal.component.scss'],
})

export class NewPasswordModalComponent implements OnInit {
	@Input() GuidId: string;
	newPasswordForm: FormGroup;
	hasMin = false;
	hasCap = false;
	hasLow = false;
	hasNum = false;
	hasSym = false;
	faTimes = faTimes;
	faCheck = faCheck;

	// retrieve information
	get newPassword1() { return this.newPasswordForm.get('newPassword1'); }
	get newPassword2() { return this.newPasswordForm.get('newPassword2'); }

constructor(private modalService: NgbModal,
		public dService: DataService,
		private formbuilder: FormBuilder,
		public router: Router,
		public route: ActivatedRoute) { }

	ngOnInit(): void {
		// Initialize parentForm & validate

			this.newPasswordForm = this.formbuilder.group({
			// email: ['', [Validators.required, Validators.email]],
			newPassword1: ['', [Validators.required, Validators.minLength(6)]],
			newPassword2: ['', [Validators.required, this.passwordValidator('newPassword1')]],
			pIdOfUser: [environment.projectId],
		});

	}

	passwordValidator(confirmPasswordInput: string) {
		let confirmPasswordControl: FormControl;
		let passwordControl: FormControl;
		return (control: FormControl) => {
			if (!control.parent) {
				return null;
			}
			if (!confirmPasswordControl) {
				confirmPasswordControl = control;
				passwordControl = control.parent.get(confirmPasswordInput) as FormControl;
				passwordControl.valueChanges.subscribe(() => {
					confirmPasswordControl.updateValueAndValidity();
				});
			}
			if (passwordControl.value == null || confirmPasswordControl.value == null) {
				return null;
			}
			if (
				passwordControl.value.toLocaleLowerCase() !==
				confirmPasswordControl.value.toLocaleLowerCase()
			) {
				return {
					notMatch: true,
				};
			}
			return null;
		};
	}

	pwOnChange(e: any) {
		this.checkPassword(e.target.value);
	}

	checkPassword(str: string) {
		let lHasCap = false;
		let lHasLow = false;
		let lHasSym = false;
		let lHasNum = false;
		for (let i = 0; i < str.length; i++) {
			if (this.isUpper(str[i])) {
				lHasCap = true;
			} else if (this.isLower(str[i])) {
				lHasLow = true;
			} else if (this.isNumber(str[i])) {
				lHasNum = true;
			} else if (this.isSymbol(str[i])) {
				lHasSym = true;
			}
		}
		this.hasCap = lHasCap;
		this.hasLow = lHasLow;
		this.hasSym = lHasSym;
		this.hasNum = lHasNum;
		this.hasMin = str.length >= 8;
	}
	isUpper(str: string) { return !/[a-z]/.test(str) && /[A-Z]/.test(str); }
	isLower(str: string) { return /[a-z]/.test(str) && !/[A-Z]/.test(str); }
	isNumber(str: string) { return /[0-9]/.test(str); }
	isSymbol(str: string) { return /[!@#$%^&*?_~-]/.test(str); }

	open(content: any) {
		// open modal
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
	}

	changePassword() {
		// create new user obejct
		const newUserPassword: User = {
			id: 0,
			email: null,
			password: this.newPasswordForm.controls.newPassword1.value,
			projectId: environment.projectId,
			projectName: '',
		};
		if (this.GuidId) {
			this.dService.securityUpdatePassword(this.GuidId, newUserPassword).subscribe(_ => {
				alert('Password was successfuly updated');
				this.modalService.dismissAll(NewPasswordModalComponent);
				this.newPasswordForm.reset();
				this.router.navigate(['home']);
			},
			exc => {
				alert('Password was not updated. ' + exc.error);
			});
		} else {
			this.dService.updatePassword(newUserPassword.password).subscribe(x => {
					alert('Password was successfuly updated');
					this.modalService.dismissAll(NewPasswordModalComponent);
					this.newPasswordForm.reset();
				},
				exc => {
					alert('Password was not updated. ' + exc.error);
				});
		}
	}
}
