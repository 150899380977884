import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ResourceLeaf } from 'src/app/models/_module';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'pm-resource-view-grid',
	templateUrl: './resource-view-grid.component.html',
	styleUrls: ['./resource-view-grid.component.scss'],
})
export class ResourceViewGridComponent {
	@Input() item: ResourceLeaf;
	@Output() append: EventEmitter<ResourceLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Resource';
	eye = faEye;
	eyeSlash = faEyeSlash;
	constructor() { }

	appendChild(item: ResourceLeaf) {
		this.append.emit(item);
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}
}
