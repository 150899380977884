import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/_modules';
import { ActivatedRoute } from '@angular/router';
import { AppStats } from 'src/app/models/_module';

@Component({
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
	title = '';
	header = '';
	resourceUrl = 'default.png';
	appStats: AppStats;
	errorMessage: string;
	constructor(private dService: DataService, private activeRouted: ActivatedRoute) { }

	ngOnInit(): void {
		const { data, error }: { data: AppStats, error: string } = this.activeRouted.snapshot.data['page'];
		if (error != null) {
			this.errorMessage = error;
		} else {
			this.appStats = data;
			this.title = this.appStats.projectName;
			this.dService.getChildDisplayTypes().subscribe(x => { localStorage.setItem('displayData', JSON.stringify(x)); });
			this.dService.getEntityTypes().subscribe(x => { localStorage.setItem('entityData', JSON.stringify(x)); });
			this.dService.getImages().subscribe(x => { localStorage.setItem('imageData', JSON.stringify(x)); });
		}
	}
}
