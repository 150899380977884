<div class="border border-dark bg-light container-fluid" style="width:100%">
	<div class="row pt-2">
		<div class="col-3 d-flex align-self-center">
			<h5>{{ item.display }}</h5>
		</div>
		<div class="col-1"></div>
		<div class="col-5 d-flex align-items-center justify-content-center">
			<p class="mr-5">
				{{ item.value }}
			</p>
		</div>
		<div class="col-1"></div>
		<div class="col d-flex align-self-center justify-content-end">
			<div class="d-flex flex-column">
				<pm-basic-leaf-modal [item]="item" (append)="appendChild($event)" (delete)="deleteChild($event)">
				</pm-basic-leaf-modal>
			</div>
		</div>
	</div>
	<div class="row pb-2">
		<div class="col-6">
			<fa-icon *ngIf="!item.isHidden" class="fa-xs greyIcon" [icon]="eye"></fa-icon>
			<fa-icon *ngIf="item.isHidden" class="fa-xs greyIcon" [icon]="eyeSlash"></fa-icon>
			<span class="iconText pl-1 m-0">{{ item.isHidden? "Hidden" : "Visible" }}</span>
		</div>
	</div>
</div>