<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Move {{title}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form [formGroup]="form">
			<div class="form-group">
				<p>Using this feature will move this content into a neighboring folder, up one folder, or directly to the dashboard if applicable.</p>
			</div>
			<div class="form-group">
				<label for="emailOfUser">Move to Folder:
					<span class="text-danger">*</span>
				</label>
				<select class="custom-select" formControlName="moveToFolder">
					<option value="" disabled>Select Folder</option>
					<option *ngFor="let l of list" value="{{+l.id}}">{{l.title}}</option>
				</select>
			</div>
		</form>
	</div>
	<div class="modal-footer d-flex justify-content-between">
		<button type="button" class="btn btn-dark"(click)="close()">Cancel</button>
		<button type="button" class="btn btn-primary" (click)="moveContent()">Move</button>
	</div>
</ng-template>
<button id="moveBtn" class="btn btn-primary" (click)="open(content)">
	<span>
		<span class="sr-only">Move Content</span>
		<img #eyeImg src="../../../assets/imgs/import.svg" class="buttonImage" alt="Move" aria-hidden="true">
	</span>
</button>
