import { ParentList } from './parentList';

export class ContactLeaf {
	id: number;
	firstName: string;
	lastName: string;
	title: string;
	department: string;
	program: string;
	location: string;
	imageUrl: string;
	phone: string;
	phoneExtension: string;
	email: string;
	isHidden: boolean;
	parentId: number;
	entityTypeId: number;
	createUser: boolean;
	lastUpdateBy: string;
	dateCreated: Date;
	dateLastUpdated: Date;
	parentIds: string;
	parentList: ParentList[];
}
