import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContactLeaf } from 'src/app/models/_module';

@Component({
	selector: 'pm-contact-view-list',
	templateUrl: './contact-view-list.component.html',
	styleUrls: ['./contact-view-list.component.scss'],
})
export class ContactViewListComponent implements OnInit {
	@Input() item: ContactLeaf;
	@Output() append: EventEmitter<ContactLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Contact';
	constructor() { }

	ngOnInit(): void {
	}

	isImageUrlEmpty(): boolean {
		return this.item.imageUrl == null || this.item.imageUrl === '';
	}

	hasPhoneExtention(): boolean {
		return this.item.phoneExtension != null && this.item.phoneExtension !== '';
	}

	appendChild(item: ContactLeaf) {
		this.append.emit(item);
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}
}
