<pm-shared-header [title]='title' [header]='parentOfChildren?.title' [resourceUrl]='resourceUrl'
	[visibility]='visibility'></pm-shared-header>
<div class="container" *ngIf="!errorMessage" [ngSwitch]="parentOfChildren?.entityTypeName">
	<div class="row d-flex justify-space-between pl-0 pl-md-3 pr-4">
		<div class="col-12 col-md-6">
			<app-breadcrumbs [header]="header" [item]="parentOfChildren" [crumbs]="crumbs"></app-breadcrumbs>
		</div>
		<div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end pt-2 pt-md-0">
			<pm-basic-leaf-modal *ngSwitchCase="'Basic'" [pId]="id" [eTypeId]="eTypeId" (append)="appendChild($event)"
				(delete)="deleteChild($event)"></pm-basic-leaf-modal>
			<pm-contact-leaf-modal *ngSwitchCase="'Contact'" [pId]="id" [eTypeId]="eTypeId"
				(append)="appendChild($event)" (delete)="deleteChild($event)"></pm-contact-leaf-modal>
			<pm-event-leaf-modal *ngSwitchCase="'Event'" [pId]="id" [eTypeId]="eTypeId" (append)="appendChild($event)"
				(delete)="deleteChild($event)"></pm-event-leaf-modal>
			<div *ngIf="showEditor">
				<pm-html-leaf-modal *ngSwitchCase="'Html'" [pId]="id" [eTypeId]="eTypeId" (append)="appendChild($event)"
					(delete)="deleteChild($event)"></pm-html-leaf-modal>
				<pm-resource-leaf-modal *ngSwitchCase="'Resource'" [pId]="id" [eTypeId]="eTypeId"
					(append)="appendChild($event)" (delete)="deleteChild($event)"></pm-resource-leaf-modal>
			</div>
		</div>
	</div>

	<div cdkDropListGroup
		*ngIf="(parentOfChildren?.childDisplayTypeName === 'Grid')"
		class="drag-grid-container justify-content-center">

		<div cdkDropList class="gridDropList m-0" [cdkDropListEnterPredicate]="dropListEnterPredicate"
			(cdkDropListDropped)="dropListDropped($event)">
		</div>
		<div cdkDropList class="gridDropList" *ngFor="let item of dataList; let i = index"
			[cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">

			<div *ngSwitchCase="'Resource'" cdkDrag (cdkDragMoved)="dragMoved($event, item.id)"
				class="p-0 m-2 drag-grid-box">
				<pm-resource-view-grid [item]='item' (append)="appendChild($event)" (delete)="deleteChild($event)">
				</pm-resource-view-grid>
			</div>

			<div *ngSwitchCase="'Contact'" class="p-0 m-2">
				<pm-contact-view-grid [item]='item' (append)="appendChild($event)" (delete)="deleteChild($event)">
				</pm-contact-view-grid>
			</div>

			<div *ngSwitchCase="'Event'" class="p-0 m-2">
				<pm-event-view-grid [item]='item' (append)='appendChild($event)' (delete)="deleteChild($event)">
				</pm-event-view-grid>
			</div>
		</div>
	</div>

	<div cdkDropListGroup *ngIf="(parentOfChildren?.childDisplayTypeName === 'List')"
		class="drag-list-container justify-content-center container">
		<div cdkDropList class="listDropList container" [cdkDropListEnterPredicate]="dropListEnterPredicate"
			(cdkDropListDropped)="dropListDropped($event)">
		</div>
		<div cdkDropList class="listDropList container d-block" *ngFor="let item of dataList; let i = index"
			[cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
			<div cdkDrag (cdkDragMoved)="dragMoved($event, item.id)" class="drag-list-box">
				<pm-basic-view-list *ngSwitchCase="'Basic'" [item]='item' (append)='appendChild($event)'
					(delete)="deleteChild($event)"></pm-basic-view-list>
			</div>
			<div cdkDrag (cdkDragMoved)="dragMoved($event, item.id)" class="drag-list-box">
				<pm-resource-view-list *ngSwitchCase="'Resource'" [item]='item' (append)="appendChild($event)"
					(delete)="deleteChild($event)"></pm-resource-view-list>
			</div>
			<pm-contact-view-list *ngSwitchCase="'Contact'" [item]='item' (append)="appendChild($event)"
				(delete)="deleteChild($event)"></pm-contact-view-list>
			<pm-event-view-list *ngSwitchCase="'Event'" [item]='item' (append)='appendChild($event)'
				(delete)="deleteChild($event)"></pm-event-view-list>
		</div>
	</div>
	<div
		*ngIf="(parentOfChildren?.childDisplayTypeName === 'Page')"
		class="d-flex justify-content-center pr-2">

		<div *ngFor="let item of dataList; let i = index">
			<pm-html-view-grid *ngSwitchCase="'Html'" [item]='item' (append)="appendChild($event)"
				(delete)="deleteChild($event)"></pm-html-view-grid>
		</div>

		<div *ngFor="let item of dataList; let i = index">
			<pm-resource-view-grid *ngSwitchCase="'Resource'" [item]='item' (append)="appendChild($event)"
				(delete)="deleteChild($event)" class="p-0 m-2 drag-grid-box"></pm-resource-view-grid>
		</div>
	</div>
</div>
<div class="container" *ngIf="errorMessage">
	<div class="row">
		<h3 class="col-12 text-center">{{errorMessage}}</h3>
	</div>
</div>
