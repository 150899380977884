<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{userModalTitle}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form [formGroup]="usersForm">
			<div class="form-group">
				<label for="emailOfUser">Email:
					<span class="text-danger">*</span>
				</label>
				<input id="emailOfUser" formControlName="emailOfUser" class="form-control" type="email">
				<small class="text-danger" [hidden]="!emailOfUser.hasError('email')">
					**Not a valid email address
				</small><br/>
				<label class="mt-1" for="emailOfUserConfirm">Confirm Email:
					<span class="text-danger">*</span>
				</label>
				<input id="emailOfUserConfirm" formControlName="emailOfUserConfirm" class="form-control" type="email">
				<small class="text-danger" [hidden]="!emailOfUserConfirm.hasError('notMatch')">
					**Emails do not match
				</small>
			</div>
			<div class="form-group mt-2" *ngIf="!isEdit">
				<label for="pWordOfUser">Password will be generated and emailed to user </label>
				<label *ngIf="(userId === 0)" for="pIdOfUser">Project Id: </label>
				<input *ngIf="(userId === 0)" id="pIdOfUser" formControlName="pIdOfUser" class="form-control" type="number">
			</div>
		</form>
	</div>
	<div class="modal-footer d-flex justify-content-between">
		<button type="button" class="btn btn-dark" (click)="close()">Cancel</button>
		<div>
			<button *ngIf="!isEdit" [disabled]="!usersForm.valid" id="createBtn" type="button" class="btn btn-primary"
				(click)="createUser()">Create</button>
			<button *ngIf="isEdit" [disabled]="!usersForm.valid" id="createBtn" type="button"
				class="btn btn-primary mr-2" (click)="saveUser()">Save</button>
			<button *ngIf="isEdit" id="delete" type="button" class="btn btn-danger"
				(click)="deleteUser()">Delete</button>
		</div>
	</div>
</ng-template>
<button *ngIf="isEdit" class="btn penBtn btn-background px-1 py-0" (click)="open(content)">
	<span>
		<span class="sr-only">Edit</span>
		<fa-icon [icon]="pen" class="blueIcon" alt="Edit" aria-hidden="true"></fa-icon>
	</span>
</button>
<button *ngIf="!isEdit" class="btn plusBtn bg-primary px-1 py-0" (click)="open(content)">
	<fa-icon class="fa-xs whiteIcon" [icon]="plus"></fa-icon>
	<span class="whiteIcon pl-1 m-0">Add</span>
</button>