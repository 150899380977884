import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { ChildPageComponent } from './pages/child-page/child-page.component';
import { ParentPageComponent } from './pages/parent-page/parent-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AppInfoPageComponent } from './pages/app-info-page/app-info-page.component';
import { QuillModule } from 'ngx-quill';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthGuardService, DataService, AuthHttpInterceptor } from './services/_modules';
import { SharedHeaderComponent, ChildBackBtnComponent, HiddenBtnComponent, StarRatingComponent } from './shared/_module';
import {
	BasicLeafModalComponent, ResourceLeafModalComponent, ParentFormModalComponent,
	UsersModalComponent, ContactLeafModalComponent, EventLeafModalComponent,
	AppInfoModalComponent, ForgotPasswordModalComponent, EditorModalComponent,
	NewPasswordModalComponent, MoveContentModalComponent,
} from './modals/_module';
import {
	BasicViewGridComponent, BasicViewListComponent, ResourceViewGridComponent,
	ResourceViewListComponent, ContactViewListComponent, ContactViewGridComponent,
	EventViewListComponent, EventViewGridComponent, HtmlViewGridComponent,
	ParentViewGridComponent
} from './child-views/_module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
	declarations: [
		AppComponent,
		SignInComponent,
		DashboardPageComponent,
		BasicLeafModalComponent,
		ChildPageComponent,
		ResourceLeafModalComponent,
		ParentPageComponent,
		ParentFormModalComponent,
		UsersPageComponent,
		UsersModalComponent,
		ContactLeafModalComponent,
		HomePageComponent,
		SharedHeaderComponent,
		EventLeafModalComponent,
		BasicViewGridComponent,
		BasicViewListComponent,
		ResourceViewGridComponent,
		ResourceViewListComponent,
		ContactViewListComponent,
		ContactViewGridComponent,
		EventViewListComponent,
		EventViewGridComponent,
		AppInfoPageComponent,
		AppInfoModalComponent,
		ChildBackBtnComponent,
		ForgotPasswordModalComponent,
		EditorModalComponent,
		HtmlViewGridComponent,
		NewPasswordModalComponent,
		HiddenBtnComponent,
		ParentViewGridComponent,
		MoveContentModalComponent,
		StarRatingComponent,
		BreadcrumbsComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		ReactiveFormsModule,
		HttpClientModule,
		FormsModule,
		QuillModule.forRoot({
			modules: {
				toolbar: [
					[{ 'size': [] }],
					[{ 'header': 1 }, { 'header': 2 }],
					[{ 'align': [] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ 'list': 'ordered' }, { 'list': 'bullet' }],
					['link'],
					['clean'],
				],
			},
		}),
		DragDropModule,
		FontAwesomeModule,
		NgMultiSelectDropDownModule.forRoot()
	],
	providers: [
		AuthGuardService,
		DataService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
