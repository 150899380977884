import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/_modules';
import { ForgotPasswordModalComponent } from 'src/app/modals/_module';
import { LoginRequest } from 'src/app/models/_module';

@Component({
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
	signInForm: FormGroup;
	error: string = null;
	loginUser: any;
	showSpinner = true;
	requestId: string;

	// retrieve user email and password
	get email() { return this.signInForm.get('email'); }
	get password() { return this.signInForm.get('password'); }

	constructor(
		private service: DataService,
		private formbuilder: FormBuilder,
		private router: Router,
		private modalService: NgbModal,
		private route: ActivatedRoute,
	) {
		this.requestId = this.route.snapshot.queryParams.id;
	}

	ngOnInit(): void {
		const userEmail = this.service.getCurrentUserEmailOrNull();
		if (userEmail != null) {
			this.router.navigate(['home']);
		} else {
			this.showSpinner = false;
		}

		// Init signInForm & validate
		this.signInForm = this.formbuilder.group({
			email: ['', [Validators.required, Validators.minLength(6)]],
			password: ['', Validators.required],
		});

		if (this.requestId) {
			this.service.verifyGuidId(this.requestId).subscribe(_ => {
					this.resetPassword();
				},
				() => {
						alert('Link expired, please log in to send another link');
				});
		}
	}

	forgot() {
		this.modalService.open(ForgotPasswordModalComponent);
	}

	resetPassword() {
		document.getElementById('newPasswordModal').click();
	}

	submitSignIn() {
		if (this.signInForm.invalid) {
			alert('Fix errors on form');
		} else {
			const request: LoginRequest = {
				email: this.signInForm.value.email,
				password: this.signInForm.value.password,
			};
			this.showSpinner = true;
			this.service.login(request).subscribe(
				(response: any) => {
					const { token, error, user } = response;
					if (token) {
						// store username and jwt token in local storage to keep user logged in between page refreshes
						this.service
							.saveToken(
								{
									token: token,
									role: user.userType,
									email: request.email,
								},
								token,
							)
							.then(() => {
								this.router.navigate(['home']);
							});
					} else {
						this.error = error;
						this.showSpinner = false;
					}
				}, (ex: any) => {
					this.error = ex.error;
					this.signInForm.controls['password'].reset();
					this.showSpinner = false;
				},
			);
		}
	}
}
