<div class="border border-dark bg-light container-fluid" style="width:100%">
	<div class="row">
		<div class="col-md-3 col-4 py-3">
			<img *ngIf="!isImageUrlEmpty()" class="d-block m-auto" src="{{item.imageUrl}}" height="160px" width="120px"/>
			<img *ngIf="isImageUrlEmpty()" class="d-block m-auto" src="../../../assets/imgs/no_photo.jpg" height="140px" width="120px"/>
			<div class="d-md-none d-flex mt-2 justify-content-center">
				<pm-contact-leaf-modal
					[item]="item"
					(append)="appendChild($event)"
					(delete)="deleteChild($event)"
				></pm-contact-leaf-modal>
			</div>
		</div>
		<dl class="col-md col-4 py-3 mb-0">
			<dt>Name:</dt><dd>{{ item.firstName }} {{ item.lastName }}</dd>
			<dt>Title:</dt><dd>{{ item.title }}</dd>
			<dt>Department:</dt><dd>{{ item.department }}</dd>
			<dt class="d-none d-md-block">Program:</dt><dd class="d-none d-md-block">{{ item.program }}</dd>
			<dt class="d-md-none d-block">Email:</dt><dd class="d-md-none d-block">{{ item.email }}</dd>
		</dl>
		<div class="col-md col-4 py-3">
			<dl class="mb-0">
				<dt>Location:</dt>
				<dd>{{ item.location }}</dd>
				<dt>Phone</dt>
				<dd>{{ item.phone }}&nbsp;<br class="d-md-none"/> <span *ngIf="hasPhoneExtention()">Ext:&nbsp;{{ item.phoneExtension }}</span></dd>
				<dt class="d-none d-md-block">Email:</dt>
				<dd class="d-none d-md-block">{{ item.email }}</dd>
				<dt class="d-md-none d-block">Program:</dt>
				<dd class="d-md-none d-block">{{ item.program }}</dd>
			</dl>
		</div>
		<div class="col d-md-flex d-none flex-column justify-content-center align-items-end p-3">
			<pm-contact-leaf-modal
				[item]="item"
				(append)="appendChild($event)"
				(delete)="deleteChild($event)"
			></pm-contact-leaf-modal>
		</div>
	</div>
	<div class="row" *ngIf="item.lastUpdateBy">
		<small class="col-12 d-flex justify-content-center">
			Last update by: {{item.lastUpdateBy}}
		</small>
	</div>
</div>
