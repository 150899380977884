<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Info Update</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="infoForm">

        <div class="form-group">
            <label for="about">App Info:</label>
            <textarea class="form-control" id="about" formControlName="about"  rows="4"></textarea>
        </div>
        <div class="form-group">
            <label for="fBook">Facebook: </label>
            <input maxlength="100" id="fBook" formControlName="fBook" class="form-control" type="text">
        </div>
        <div class="form-group">
            <label for="iGram">Instagram: </label>
            <input maxlength="100" id="iGram" formControlName="iGram" class="form-control" type="text">
        </div>
        <div class="form-group">
            <label for="tweet">Twitter: </label>
            <input maxlength="100" id="tweet" formControlName="tweet" class="form-control" type="text">
        </div>
        <div class="form-group">
            <label for="eMail">Email: </label>
            <input maxlength="100" id="eMail" formControlName="eMail" class="form-control" type="text">
        </div>

      </form>
		</div>

		<div class="modal-footer d-flex justify-content-between">
				<button type="button" class="btn btn-dark"(click)="modal.dismiss()">Cancel</button>
				<button type="button" class="btn btn-primary" (click)="editInfo()">Save</button>
		</div>
  </ng-template>

  <button class="btn btn-background px-1 py-0" (click)="open(content)">
    <span>
      <span class="sr-only">Edit</span>
      <fa-icon [icon]="pen" class="blueIcon fa-lg" alt="Edit" aria-hidden="true"></fa-icon>
    </span>
  </button>
