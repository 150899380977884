import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pm-child-back-btn',
	templateUrl: './child-back-btn.component.html',
	styleUrls: ['./child-back-btn.component.scss'],
})
export class ChildBackBtnComponent implements OnInit {

	@Input() parentOfChildren: any;

	constructor() { }

	ngOnInit(): void {
	}

}
