<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Editor</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="editorForm">
        <div class="form-group">
          <label for="title">Title:</label>
          <input type="text" formControlName="title" id="title" class="form-control">
        </div>
        <div class="form-group">
          <label for="editor">Value:</label>
          <quill-editor id="editor" formControlName="editor" [styles]="{height: '250px'}"></quill-editor>
        </div>
        <div class="row">
          <div class="col">
            <label for="entityTypeOfParent" class="bold">Editor Status:</label><br />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <select class="custom-select" id="isHidden" formControlName="isHidden">
              <option value="" disabled>Choose Status</option>
              <option [ngValue]="false">Visible</option>
              <option [ngValue]="true">Hidden</option>
            </select>
          </div>
        </div>
				<div class="row mt-4" *ngIf="showEdit">
					<small class="col-6 text-muted">Created: <span *ngIf="createdDate; else not_applicable">{{createdDate | date:'MM/dd/yy'}}</span></small>
					<small class="col-6 text-muted text-right">Updated: <span *ngIf="lastUpdated; else not_applicable">{{lastUpdated | date:'MM/dd/yy'}}</span></small>
				</div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
			<div>
				<button type="button" class="btn btn-dark"(click)="modal.dismiss()">Cancel</button>
			</div>
			<div>
				<button *ngIf="!showEdit" id="createBtn" type="button" class="btn btn-primary" (click)="createChild()">Create</button>
				<button *ngIf="showEdit" id="editBtn" type="button" class="btn btn-primary mr-2" (click)="editChild()">Save</button>
				<button *ngIf="showEdit" id="deleteBtn" type="button" class="btn btn-danger" (click)="deleteChild()">Delete</button>
			</div>
    </div>
  </ng-template>

	<ng-template #not_applicable>N/A</ng-template>

  <button *ngIf="showEdit" class="btn penBtn btn-background px-1 py-0" (click)="open(content)">
    <span>
      <span class="sr-only">Edit</span>
      <!-- <img src="../../../assets/imgs/edit.svg" class="buttonImage" alt="Edit" aria-hidden="true"> -->
      <fa-icon [icon]="pen" class="blueIcon" alt="Edit" aria-hidden="true"></fa-icon>
    </span>
  </button>
  <button *ngIf="!showEdit" class="btn plusBtn bg-primary px-1 py-0" (click)="open(content)">
    <fa-icon class="fa-xs whiteIcon" [icon]="plus"></fa-icon>
    <span class="whiteIcon pl-1 m-0">Add</span>
  </button>
