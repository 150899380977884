import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { CommonModule } from '@angular/common';
import { ChildPageComponent } from './pages/child-page/child-page.component';
import { ParentPageComponent } from './pages/parent-page/parent-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { AuthGuardService } from './services/auth.guard.service';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AppInfoPageComponent } from './pages/app-info-page/app-info-page.component';
import { PrivacyPageComponent } from './pages/privacy-page/privacy-page.component';
import { GetParentPageResolver, GetDashboardResolver, GetChildrenResolver, GetAppStatsResolver } from './app.resolver';

const routes: Routes = [
	{ path: 'sign-in', component: SignInComponent },
	{ path: 'privacy', component: PrivacyPageComponent },
	{
		path: 'home', component: HomePageComponent, canActivate: [AuthGuardService],
		resolve: {
			page: GetAppStatsResolver,
		},
	},
	{ path: 'users', component: UsersPageComponent, canActivate: [AuthGuardService] },
	{ path: 'info', component: AppInfoPageComponent, canActivate: [AuthGuardService] },
	{
		path: 'dashboard', component: DashboardPageComponent, canActivate: [AuthGuardService],
		resolve: {
			dashboard: GetDashboardResolver,
		},
	},
	{
		path: 'parent/:id', component: ParentPageComponent, canActivate: [AuthGuardService],
		resolve: {
			page: GetParentPageResolver,
		},
	},
	{
		path: 'child/:id', component: ChildPageComponent, canActivate: [AuthGuardService],
		resolve: {
			page: GetChildrenResolver,
		},
	},
	{ path: '', redirectTo: '/sign-in', pathMatch: 'full' },
	{ path: '*', redirectTo: '/home' },
	{ path: '**', redirectTo: '/home' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes),
		CommonModule],
	exports: [RouterModule],
	providers: [GetParentPageResolver, GetDashboardResolver, GetChildrenResolver, GetAppStatsResolver],
})
export class AppRoutingModule { }
