import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HtmlLeaf } from 'src/app/models/html-leaf';
import { DataService } from 'src/app/services/data.service';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
// import { NgxEditorServiceConfig } from 'ngx-editor/lib/ngx-editor.service';


@Component({
	selector: 'pm-html-leaf-modal',
	templateUrl: './editor-modal.component.html',
	styleUrls: ['./editor-modal.component.scss', '../styles.scss'],
})
export class EditorModalComponent implements OnInit {

	btnTxt = '';
	content: any;
	@Input() item: any;
	@Input() pId: number;
	@Input() eTypeId: number;
	@Output() append: EventEmitter<HtmlLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();

	showEdit: boolean;

	editorForm: FormGroup;
	pen = faPen;
	plus = faPlus;
	createdDate: Date;
	lastUpdated: Date;

	get title() { return this.editorForm.get('title'); }
	get editor() { return this.editorForm.get('editor'); }
	get isHidden() { return this.editorForm.get('isHidden'); }

	constructor(private modalService: NgbModal, public formbuilder: FormBuilder, private dService: DataService) { }

	ngOnInit(): void {
		this.editorForm = this.formbuilder.group({
			title: [''],
			editor: [''],
			isHidden: [false],
		});
		this.showEdit = this.item != null;
	}

	open(content: any) {
		if (this.showEdit) {
			this.createdDate = this.dService.getTimeFromUtcDate(this.item.dateCreated);
			this.lastUpdated = this.dService.getTimeFromUtcDate(this.item.dateLastUpdated);
		}
		this.title.setValue(this.item?.title);
		this.editor.setValue(this.item?.value);
		this.isHidden.setValue(this.item?.isHidden);
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg' });
		const input = document.getElementById('editor');
		input.onpaste = function(e) {
			e.preventDefault();
		};
	}

	createChild() {
		// set fields of html child
		const htmlChild: HtmlLeaf = {
			id: 0,
			title: this.title.value,
			value: this.editor.value,
			isHidden: this.isHidden.value,
			parentId: this.pId,
			entityTypeId: this.eTypeId,
			isDeleted: false,
			lastUpdateBy: null,
			dateCreated: null,
			dateLastUpdated: null,
		};
		this.save(htmlChild);
	}

	editChild() {
		const newItem = Object.assign({}, this.item);
		newItem.title = this.title.value;
		newItem.value = this.editor.value;
		newItem.isHidden = this.isHidden.value;
		this.save(newItem);
	}

	save(item: HtmlLeaf) {
		const isNew = item.id === 0;
		this.dService.upsertHtmlLeaf(item).subscribe(newLeaf => {
			alert(`Html content has been ${isNew ? 'created' : 'updated'}.`);
			if (isNew) {
				this.append.emit(newLeaf);
			} else {
				this.item.title = newLeaf.title;
				this.item.value = newLeaf.value;
				this.item.lastUpdateBy = newLeaf.lastUpdateBy;
				this.item.isHidden = newLeaf.isHidden;
				this.item.dateCreated = newLeaf.dateCreated;
				this.item.dateLastUpdated = newLeaf.dateLastUpdated;
				this.append.emit();
			}
			this.modalService.dismissAll(EditorModalComponent);
		});
	}

	cancelEditor() {
		this.modalService.dismissAll(EditorModalComponent);
	}

	deleteChild() {
		const targetId = this.item.id;
		if (confirm('Are you sure you want to delete?')) {
			// pass item to data service, to be deleted
			this.dService.deleteHtmlLeaf(targetId).subscribe(x => {
				alert('Html content has been deleted');
				this.delete.emit(targetId);
				// close modal
				this.modalService.dismissAll(EditorModalComponent);
			}, _ => {
				alert('Error attempting to delete html content');
			});
		}
	}
}
