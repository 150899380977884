import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ResourceLeaf } from 'src/app/models/_module';

@Component({
	selector: 'pm-resource-view-list',
	templateUrl: './resource-view-list.component.html',
	styleUrls: ['./resource-view-list.component.scss'],
})
export class ResourceViewListComponent {
	@Input() item: ResourceLeaf;
	@Output() append: EventEmitter<ResourceLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Resource';
	constructor() { }

	appendChild(item: ResourceLeaf) {
		this.append.emit(item);
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}
}
