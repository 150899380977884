<div class="card float m-4">
	<div class="card-body" [ngClass]="{'pb-1': item.lastUpdateBy}">
		<div class="container">
			<div class="row">
				<div class="col-12 d-flex justify-content-center">
					<b>
						<p class="card-text pl-1">
							{{ item.title? item.title : "N/a" }}
						</p>
					</b>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div #editorTxt></div>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-6">
					<fa-icon *ngIf="!item.isHidden" class="fa-xs greyIcon" [icon]="eye"></fa-icon>
					<fa-icon *ngIf="item.isHidden" class="fa-xs greyIcon" [icon]="eyeSlash"></fa-icon>
					<span class="iconText pl-1 m-0">{{ item.isHidden? "Hidden" : "Visible" }}</span>
				</div>
				<div class="col-6 d-flex justify-content-end">
					<pm-html-leaf-modal [item]="item" (append)="appendChild($event)" (delete)="deleteChild($event)">
					</pm-html-leaf-modal>
				</div>
			</div>
		</div>
	</div>
</div>