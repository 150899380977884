import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Info } from 'src/app/models/info';
import { DataService } from 'src/app/services/data.service';
import { faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'pm-info-modal',
	templateUrl: './app-info-modal.component.html',
	styleUrls: ['./app-info-modal.component.scss', '../styles.scss'],
})
export class AppInfoModalComponent implements OnInit {
	@Input() appInfo: Info;
	@Output() refreshPage: EventEmitter<any> = new EventEmitter();
	infoForm: FormGroup;
	pen = faPen;

	get about() { return this.infoForm.get('about'); }
	get fBook() { return this.infoForm.get('fBook'); }
	get iGram() { return this.infoForm.get('iGram'); }
	get tweet() { return this.infoForm.get('tweet'); }
	get eMail() { return this.infoForm.get('eMail'); }

	constructor(public modalService: NgbModal,
		private formbuilder: FormBuilder,
		private dService: DataService,
		private renderer: Renderer2,
	) {
	}

	ngOnInit(): void {
		this.infoForm = this.formbuilder.group({
			about: [''],
			fBook: ['', (Validators.maxLength(100))],
			iGram: ['', (Validators.maxLength(100))],
			tweet: ['', (Validators.maxLength(100))],
			eMail: ['', (Validators.maxLength(100))],
		});
	}

	open(content: any) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
		try {
			this.about.setValue(JSON.parse(this.appInfo?.aboutUs));
		} catch {
			this.about.setValue(this.appInfo?.aboutUs);
		}
		this.fBook.setValue(this.appInfo?.facebook);
		this.iGram.setValue(this.appInfo?.instagram);
		this.tweet.setValue(this.appInfo?.twitter);
		this.eMail.setValue(this.appInfo?.contactEmail);
		this.renderer.selectRootElement('#about').focus();
	}

	editInfo() {
		// set updated values to appInfo
		// if any social/email is empty, save as null
		this.appInfo.facebook = this.fBook.value === '' ? null : this.fBook.value;
		this.appInfo.instagram = this.iGram.value === '' ? null : this.iGram.value;
		this.appInfo.twitter = this.tweet.value === '' ? null : this.tweet.value;
		this.appInfo.contactEmail = this.eMail.value === '' ? null : this.eMail.value;
		// stringify to preserve newline character
		this.appInfo.aboutUs = JSON.stringify(this.about.value);
		// pass this.item to data service, to be edited
		this.dService.upsertAppInfo(this.appInfo).subscribe(_ => {
			alert('Info has been updated');
			this.refreshPage.emit();
			this.modalService.dismissAll(AppInfoModalComponent);
		});
	}
}
