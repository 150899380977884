import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasicLeaf } from 'src/app/models/_module';

@Component({
	selector: 'pm-basic-view-grid',
	templateUrl: './basic-view-grid.component.html',
	styleUrls: ['./basic-view-grid.component.scss'],
})
export class BasicViewGridComponent implements OnInit {
	@Input() item: BasicLeaf;
	@Output() append: EventEmitter<BasicLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Basic';
	constructor() { }

	ngOnInit(): void {
	}

	appendChild(newItem: BasicLeaf) {
		this.append.emit(newItem);
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}
}
