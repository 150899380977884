<div class="container">
    <div>
        <div>
            <h1>Strong Start App Privacy Statement</h1><br />
            <h4>Overview</h4>
            <p>
                Strong Start is committed to respecting the privacy of our users. We strive to provide a safe, secure user experience. This Privacy Statement sets forth the online data collection and usage policies and practices that apply to all of our Apps. By using the Strong Start App, you consent to the policies and practices described in this Statement.
            </p>
            <p>
                Your data will be stored and processed in the United States. If you access the Strong Start App from outside the United States, your usage of the site constitutes consent to the transfer of your data out of your country and to the United States.
            </p>
            <p>
                Our sites contain links to other Web sites over which we have no control. Strong Start is not responsible for the privacy policies or practices of other Web sites to which you choose to link from the Strong Start App. We encourage you to review the privacy policies of those other Web sites so you can understand how they collect, use, and share your information. This Privacy Statement applies solely to the information we collect on the Strong Start App, and does not apply to information we collect in any other fashion.
            </p><br />
            <h4>Collection and Retention of Information</h4>
            <p>
                In some areas of our App, Strong Start requests or may request that you provide personal information, including your name, address, e-mail address, telephone, social security number, contact information and any other information from which your identity is discernible. In other areas, Strong Start collects or may collect demographic information that is not unique to you such as your ZIP code, age, preferences, gender or interests. Sometimes we collect or may collect a combination of the two types of information.
            </p>
            <p>
                We also gather or may gather certain information about your use of our App, such as what areas you visit and what services you access. Moreover, there is information about your computer hardware and software that is or may be collected by Strong Start. This information can include without limitation your IP address, browser type, domain names, access times and referring Web site addresses.
            </p><br />
            <h4> Use of Information by Strong Start</h4>
            <p>
                We use the information we gather on the Strong Start App, whether personal, demographic, collective or technical, for the purpose of operating and improving the Strong Start App, fostering a positive user experience, and delivering the products and services that we offer.
            </p>
            <p>
                We may use your contact information in order to send you e-mail or other communications regarding updates at the Strong Start App. The nature and frequency of these messages will vary depending on the information we have about you.
            </p>
            <br />
            <h4>Use of Cookies</h4>
            <p>
                The Strong Start App uses "cookies" to help personalize your online experience. A cookie is a text file that is placed on your hard drive by an App page server. Cookies are not used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to your computer and can only be read by a Web server in the domain that issued the cookie to you.
            </p>
            <p>
                One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Strong Start App, or register for services, a cookie helps us to recall your specific information (such as username, password, and preferences). Because of our use of cookies, we can deliver faster and more accurate results and a more personalized site experience. When you return to the Strong Start App, the information you previously provided can be retrieved, so you can easily use the features that you customized.
            </p>
            <p>
                You may have the ability to accept or decline cookies. Apps automatically accept cookies, but you can usually modify your browser setting to decline all cookies if you prefer. Alternatively, you may be able to modify your browser setting to notify you each time a cookie is tendered and permit you to accept or decline cookies on an individual basis. If you choose to decline cookies, however, that may hinder performance and negatively impact your experience on the Strong Start App.
            </p><br />
            <h4>Security</h4>
            <p>
                Strong Start App has implemented reasonable technical and organizational measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, or disclosure. However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures or use your personal information for improper purposes.
            </p>
            <p>
                When you place an order online at Strong Start App, your personal information is protected through the use of encryption, such as the Secure Socket Layer ("SSL") protocol. SSL makes it difficult for your personal information to be intercepted while being transmitted.
            </p><br />
            <h4>Children</h4>
            <p>
                Strong Start App is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.
            </p>
            <br />
            <h4>Changes to Privacy Statement</h4>
            <p>
                If we decide to change our Privacy Statement for Strong Start App, we will post those changes here so that you will always know what information we gather, how we might use that information, and to whom we will disclose it.
            </p><br /><br />
        </div>
    </div>
</div>