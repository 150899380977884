import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataService } from './services/data.service';

class ToolkitResolver {
	constructor(protected api: DataService, protected router: Router) { }

	handleError(e: any): Observable<any> {
		let message = 'Unknown error occured.';
		const statusLevel = Math.floor(e.status / 100);
		if (statusLevel === 4) {
			alert('Session has expired.');
			this.api.logout();
			this.router.navigate(['']);
			return EMPTY;
		} else if (statusLevel === 5) {
			message = 'Server error. Please contact site admin.';
		}
		return of({ data: null, error: message });
	}
}

@Injectable()
export class GetAppStatsResolver extends ToolkitResolver implements Resolve<any> {
	constructor(api: DataService, router: Router) {
		super(api, router);
	}
	resolve() {
		return this.api.getProjectAppStats().pipe(
			map(result => ({ data: result })),
			catchError(ex => {
				return this.handleError(ex);
			}),
		);
	}
}

@Injectable()
export class GetDashboardResolver extends ToolkitResolver implements Resolve<any> {
	constructor(api: DataService, router: Router) {
		super(api, router);
	}
	resolve() {
		return this.api.getDashboard().pipe(
			map(result => ({ data: result })),
			catchError(ex => {
				return this.handleError(ex);
			}),
		);
	}
}

@Injectable()
export class GetParentPageResolver extends ToolkitResolver implements Resolve<any> {
	constructor(api: DataService, router: Router) {
		super(api, router);
	}
	resolve(route: ActivatedRouteSnapshot) {
		const paramId = route.paramMap.get('id');
		const id = paramId != null ? +paramId : 0;

		return this.api.getParentPageById(id).pipe(
			map(result => ({ data: result })),
			catchError(ex => {
				return this.handleError(ex);
			}),
		);
	}
}

@Injectable()
export class GetChildrenResolver extends ToolkitResolver implements Resolve<any> {
	constructor(api: DataService, router: Router) {
		super(api, router);
	}
	resolve(route: ActivatedRouteSnapshot) {
		const paramId = route.paramMap.get('id');
		const id = paramId != null ? +paramId : 0;
		return this.api.getParentPageById(id).pipe(
			map(result => ({ data: result })),
			catchError(ex => {
				return this.handleError(ex);
			}),
		);
	}
}
