<header class="bg-primary">
  <nav class='navbar navbar-expand-md navbar-toggleable-md navbar-dark border-bottom box-shadow mb-3'>
    <div class="container">
			<div>
        <a [routerLink]='["/home"]'><img class="navbar-brand py-0" height='45px' src='../../assets/imgs/{{resourceUrl}}'/></a>
        <a class="navbar-brand text-white py-0" [routerLink]='["/home"]'>{{title}}&nbsp;</a>
				<!-- <span class="nav-text text-white py-0 mr-3" style="cursor:default">{{header}}</span>
				<span *ngIf="visibility == 'visible' || visibility == 'invisible'">
					<img src="../../../assets/imgs/{{visibility == 'visible' ? 'eye-o' : 'eye-c'}}.svg">
				</span> -->
			</div>

      <button role="button" class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
              aria-label="Toggle navigation" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse d-md-inline-flex flex-md-row-reverse" [ngClass]='{"show": isExpanded}'>
        <ul class="navbar-nav">
					<li class="nav-item d-inline-flex justify-content-center" style="cursor:pointer">
            <pm-new-password-modal></pm-new-password-modal>
	        </li>
          <li class="nav-item d-inline-flex justify-content-center" style="cursor:pointer">
						<a class="nav-link text-white" (click)='logout()'>Logout</a>
					</li>
        </ul>
      </div>
    </div>
  </nav>
</header>
