import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/_modules';
import { ViewportRuler } from '@angular/cdk/overlay';
import { DragNDropComponent } from 'src/app/models/dragndropcomp';
import { ChildDisplayType, Parent } from 'src/app/models/_module';

@Component({
	templateUrl: './child-page.component.html',
	styleUrls: ['./child-page.component.scss'],
})

export class ChildPageComponent extends DragNDropComponent<any> implements OnInit, AfterViewInit {
	eTypeId: number;
	parentOfChildren: Parent;
	title = '';
	header = '';
	resourceUrl = 'default.png';
	visibility = '';

	showEditor = true;
	displayTypes: ChildDisplayType[];
	errorMessage: string;
	crumbs: any;

	constructor(
		public router: Router,
		private service: DataService,
		public route: ActivatedRoute,
		viewportRuler: ViewportRuler,

	) {
		super(viewportRuler);
	}

	ngOnInit(): void {
		const { data, error }: { data: { breadCrumbs: [], parent: Parent, children: any[] }, error: string } = this.route.snapshot.data['page'];
		if (error != null) {
			this.errorMessage = error;
		} else {
			this.crumbs = data.breadCrumbs;
			this.parentOfChildren = data.parent;
			this.id = this.parentOfChildren.id;
			this.title = this.parentOfChildren.projectName;
			this.header = data.parent.title;
			this.visibility = this.parentOfChildren.isHiddenDeep ? 'invisible' : 'visible';
			this.eTypeId = this.parentOfChildren.childEntityTypeId;
			this.dataList = data.children;
			this.showEditor = !(this.dataList.length > 0
				&& this.parentOfChildren.childDisplayTypeName === 'Page'
				&& (this.parentOfChildren.entityTypeName === 'Html' || this.parentOfChildren.entityTypeName === 'Resource'));

			const displayTypes = localStorage.getItem('displayData');
			this.displayTypes = JSON.parse(displayTypes);
		}
	}

	ngAfterViewInit(): void {
		this.setPhElement();
	}

	appendChild(newItem: any) {
		if (newItem != null) {
			this.dataList.push(newItem);
			const type = this.parentOfChildren.entityTypeName;
			const display = this.parentOfChildren.childDisplayTypeName;
			this.showEditor = !(display === 'Page' && (type === 'Html' || type === 'Resource'));
		}
	}

	deleteChild(id: number) {
		this.dataList = this.dataList.filter(x => x.id !== id);
		this.showEditor = true;
	}

	showChangeDisplay() {
		switch (this.parentOfChildren?.entityTypeName) {
			case null:
			case 'Basic':
			case 'Html':
				return false;
			case 'Resource':
				return !(this.parentOfChildren.childDisplayTypeName === 'Page' || this.dataList.length === 0);
			default:
				return this.dataList.length > 0;
		}
	}

	changeStyle() {
		this.changeStyleHelper();
		setTimeout(() => {
			const response = confirm('Would you like to save this change?');
			if (response) {
				this.parentOfChildren.childDisplayTypeId = this.displayTypes.find(x => x.type === this.parentOfChildren.childDisplayTypeName).id;
				this.service.upsertParent(this.parentOfChildren).subscribe(_ => {
					alert(`${this.parentOfChildren.title} has been updated`);
				});
			} else {
				this.changeStyleHelper();
			}
		}, 1200);
	}

	private changeStyleHelper() {
		if (this.parentOfChildren.childDisplayTypeName === 'Grid') {
			this.parentOfChildren.childDisplayTypeName = 'List';
		} else if (this.parentOfChildren.childDisplayTypeName === 'List') {
			this.parentOfChildren.childDisplayTypeName = 'Grid';
		}
	}

	actionMove(id: number, movingId: number, targetIndex: number): void {
		const type: string = this.parentOfChildren.entityTypeName;
		this.service.moveContentToIndex(type.toLowerCase(), id, movingId, targetIndex).subscribe(x => {
			if (!x) {
				alert('Unable to save order changes right now. Try again later.');
			}
		});
	}
}
