import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Parent } from 'src/app/models/parent';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { EntityType, ChildDisplayType, ImageData } from 'src/app/models/_module';
import { environment } from 'src/environments/environment';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'pm-parent-form-modal',
	templateUrl: './parent-form-modal.component.html',
	styleUrls: ['./parent-form-modal.component.scss', '../styles.scss'],
})
export class ParentFormModalComponent implements OnInit {

	parentForm: FormGroup;
	@Input() p: Parent;
	@Input() type: string;
	@Input() noMove: string;
	@Output() append: EventEmitter<Parent> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	@Output() updatedTitle: EventEmitter<string> = new EventEmitter();
	entityType: any;
	IconArr = [];
	iconItems = [];
	pen = faPen;
	plus = faPlus;
	isEdit = false;

	pImages: ImageData[] = [];
	pEntityTypes: EntityType[] = [];
	pChildDisplayTypes: ChildDisplayType[] = [];

	cid: number;
	eid: number;
	iid: number;
	parentId: number;
	iconInnerText: string;
	showImage = true;
	saveTry = false;


	// entity types
	allowedTypes: string[] = [];
	urlType: string;
	list: any[];

	// retrieve information
	get titleOfParent() { return this.parentForm.get('titleOfParent'); }
	get detailsOfParent() { return this.parentForm.get('detailsOfParent'); }
	get imageNameOfParent() { return this.parentForm.get('imageNameOfParent'); }
	get displayTypeOfParent() { return this.parentForm.get('displayTypeOfParent'); }
	get entityTypeOfParent() { return this.parentForm.get('entityTypeOfParent'); }
	get isHidden() { return this.parentForm.get('isHidden'); }
	get folderLocation() { return this.parentForm.get('folderLocation'); }

	constructor(private modalService: NgbModal,
		public dService: DataService,
		private formbuilder: FormBuilder,
		public router: Router,
		public route: ActivatedRoute,
	) { }

	setupComponent(params: Params) {

		this.parentId = params.id;
		if (this.parentId == null) {
			this.parentId = 0;
		}
		if (this.p != null) {
			this.isEdit = true;
			this.dService.getMoveData('parent', this.p.id).subscribe(x => {
				this.list = x;
			});
		} else {
			this.list = [];
			this.list.push({
				id: this.parentId,
				projectId: environment.projectId,
				title: 'Current Folder',
			});
		}
		this.iconInnerText = 'Select One';

	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.setupComponent(params);
		});

		// Initialize parentForm & validate
		this.parentForm = this.formbuilder.group({
			titleOfParent: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(this.parentId === 0 ? 16 : 30)]],
			detailsOfParent: [''],
			imageNameOfParent: [''],
			displayTypeOfParent: ['', [Validators.required]],
			entityTypeOfParent: ['', [Validators.required, this.TypeValidator('displayTypeOfParent')]],
			isHidden: [false],
			folderLocation: [this.parentId],
		});

		// get meta data from localstorage
		const imageJson = localStorage.getItem('imageData');
		if (imageJson != null) {
			this.pImages = JSON.parse(imageJson);
		} else {
			this.dService.getImages().subscribe(x => this.pImages = x);
		}
		const entityJson = localStorage.getItem('entityData');
		if (entityJson != null) {
			this.pEntityTypes = JSON.parse(entityJson);
		} else {
			this.dService.getEntityTypes().subscribe(x => this.pEntityTypes = x);
		}
		const displayJson = localStorage.getItem('displayData');
		if (displayJson != null) {
			this.pChildDisplayTypes = JSON.parse(displayJson);
		} else {
			this.dService.getChildDisplayTypes().subscribe(x => this.pChildDisplayTypes = x);
		}

		this.allowedTypes = this.pEntityTypes.map(x => x.type);
	}

	open(content: any) {
		this.parentForm.reset();
		this.saveTry = false;
		// open modal
		this.titleOfParent.setValue(this.p?.title);
		this.detailsOfParent.setValue(this.p?.details);
		this.imageNameOfParent.setValue(this.p?.imageName);

		this.iconInnerText = this.p?.imageName ?? 'Select One';

		if (this.p?.imageName == null) {
			this.showImage = false;
		}

		this.displayTypeOfParent.setValue(this.p?.childDisplayTypeName);
		this.entityTypeOfParent.setValue(this.p?.entityTypeName);
		this.isHidden.setValue(this.p?.isHidden);
		if (this.isEdit) {
			this.folderLocation.setValue(this.p?.parentId);
		} else {
			this.folderLocation.setValue(this.parentId);
		}

		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
	}

	createParent() {
		if (this.parentForm.invalid) {
			this.saveTry = true;
			return;
		}
		const imageName = this.imageNameOfParent.value;
		const cdName = this.displayTypeOfParent.value;
		const ceName = this.entityTypeOfParent.value;
		const imageId = this.pImages.find(t => t.resource === imageName)?.id ?? null;
		// set displayType, entityType, and imageName
		const newParent: Parent = {
			id: 0,
			parentId: +this.folderLocation.value,
			title: this.titleOfParent.value,
			details: this.detailsOfParent.value,
			childDisplayTypeId: this.pChildDisplayTypes.find(x => x.type === cdName).id,
			childDisplayTypeName: cdName,
			childEntityTypeId: this.pEntityTypes.find(x => x.type === ceName).id,
			entityTypeName: ceName,
			imageId,
			imageName,
			projectId: environment.projectId,
			orderIndex: null,
			projectName: null,
			isHidden: this.isHidden.value,
			isHiddenDeep: null,
			lastUpdateBy: null,
		};
		this.save(newParent);
	}
	editParent() {
		if (this.parentForm.invalid) {
			this.saveTry = true;
			return;
		}
		// get data
		const parent = new Parent(this.p);
		const isMove = parent.parentId !== this.folderLocation.value;
		parent.title = this.titleOfParent.value;
		parent.details = this.detailsOfParent.value;
		parent.imageName = this.imageNameOfParent.value;
		parent.childDisplayTypeName = this.displayTypeOfParent.value;
		parent.entityTypeName = this.entityTypeOfParent.value;
		parent.imageId = this.pImages.find(t => t.resource === parent.imageName)?.id ?? null;
		parent.childDisplayTypeId = this.pChildDisplayTypes.find(x => x.type === parent.childDisplayTypeName).id;
		parent.childEntityTypeId = this.pEntityTypes.find(x => x.type === parent.entityTypeName).id;
		parent.isHidden = this.isHidden.value;
		parent.parentId = this.folderLocation.value;
		parent.orderIndex = isMove ? null : parent.orderIndex;

		this.save(parent);
	}

	save(item: Parent) {
		const isEdit = item.id !== 0;
		this.dService.upsertParent(item).subscribe(x => {
			item.lastUpdateBy = this.dService.getCurrentUserEmailOrNull();
			alert(`${item.title} was successfuly ${isEdit ? 'updated' : 'created'}`);
			if (!isEdit) {
				item.id = x;
				if (this.parentId === this.folderLocation.value) {
					this.append.emit(item);
				}
			} else {
				this.p.title = item.title;
				this.p.details = item.details;
				this.p.imageName = item.imageName;
				this.p.childDisplayTypeName = item.childDisplayTypeName;
				this.p.entityTypeName = item.entityTypeName;
				this.p.imageId = item.imageId;
				this.p.childDisplayTypeId = item.childDisplayTypeId;
				this.p.childEntityTypeId = item.childEntityTypeId;
				this.p.lastUpdateBy = item.lastUpdateBy;
				this.p.isHidden = item.isHidden;
				this.p.parentId = this.folderLocation.value;
				if ((+this.parentId) === this.p.parentId) {
					this.updatedTitle.emit(item.title);
				} else {
					this.delete.emit(this.p.id);
				}
			}
			this.modalService.dismissAll(ParentFormModalComponent);
		}, _ => {
			alert('Failed to update folder');
		});
	}

	deleteParent() {
		const targetId = this.p.id;
		if (confirm('Are you sure you want to delete?')) {
			this.dService.deleteParent(targetId).subscribe(x => {
				// close modal
				this.modalService.dismissAll(ParentFormModalComponent);
				this.delete.emit(targetId);
				alert('Parent was successful deleted!');
			}, exc => {
				alert('Parent was not deleted. ' + exc.error);
			});
		}
	}

	getTheImg(name: string) {
		// set value of iconInput form control
		this.imageNameOfParent.setValue(name);

		const imageNameOfParent = document.getElementById('imageNameOfParent');
		const iconDropdown = document.getElementById('iconDropdown');
		// set innertext to reflect selected dropdown item
		iconDropdown.innerText = name;
		imageNameOfParent.innerText = name;
	}

	cancelParent() {
		this.saveTry = false;
		this.parentForm.reset();
		this.modalService.dismissAll(ParentFormModalComponent);
	}
	typeChange() {
		switch (this.displayTypeOfParent.value) {
			case 'Page': this.allowedTypes = ['Html', 'Resource']; break;
			case 'Grid': this.allowedTypes = this.pEntityTypes.filter(x => x.type !== 'Html' && x.type !== 'Basic').map(x => x.type); break;
			default: this.allowedTypes = this.pEntityTypes.filter(x => x.type !== 'Html').map(x => x.type); break;
		}
	}
	TypeValidator(displayTypeInput: string) {
		let entityTypeControl: FormControl;
		let displayTypeControl: FormControl;

		return (control: FormControl) => {
			if (!control.parent) {
				return null;
			}
			if (!entityTypeControl) {
				entityTypeControl = control;
				displayTypeControl = control.parent.get(displayTypeInput) as FormControl;
				// whenever display type changes, update validity
				displayTypeControl.valueChanges.subscribe(() => {
					entityTypeControl.updateValueAndValidity();
				});
			}
			if (this.allowedTypes.indexOf(entityTypeControl.value) < 0) {
				return {
					invalidType: true,
				};
			}
			return null;
		};
	}
}
