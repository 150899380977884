import { Time } from '@angular/common';
import { Url } from 'url';
import { StarRatingComponent } from '../shared/_module';

export class EventLeaf {
	id: number;
	title: string;
	startTime: Date;
	endTime: Date;
	description: string;
	isVirtual: boolean;
	location: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	linkUrl: Url;
	linkName: string;
	fileUrl: string;
	fileName: string;
	host1Name: string;
	host1Title: string;
	host1ImageUrl: string;
	host2Name: string;
	host2Title: string;
	host2ImageUrl: string;
	host3Name: string;
	host3Title: string;
	host3ImageUrl: string;
	isHidden: boolean;
	parentId: number;
	parentTitle: string = null;
	entityTypeId: number;
	entityTypeName: string = null;
	lastUpdateBy: string = null;
	fileUploadDate: Date;
	dateCreated: Date = null;
	dateLastUpdated: Date = null;
	constructor(
		id: number,
		title: string,
		startTime: Date,
		endTime: Date,
		description: string,
		isVirtual: boolean,
		location: string,
		address: string,
		city: string,
		state: string,
		zip: string,
		linkUrl: Url,
		linkName: string,
		fileUrl: string,
		fileName: string,
		fileUploadDate: Date,
		host1Name: string,
		host1Title: string,
		host1ImageUrl: string,
		host2Name: string,
		host2Title: string,
		host2ImageUrl: string,
		host3Name: string,
		host3Title: string,
		host3ImageUrl: string,
		isHidden: boolean,
		parentId: number,
		entityTypeId: number,
	) {
		this.id = id;
		this.title = title;
		this.startTime = startTime;
		this.endTime = endTime;
		this.description = description;
		this.isVirtual = isVirtual;
		this.location = location;
		this.address = address;
		this.city = city;
		this.state = state;
		this.zip = zip;
		this.linkUrl = linkUrl;
		this.linkName = linkName;
		this.fileUrl = fileUrl;
		this.fileName = fileName;
		this.fileUploadDate = fileUploadDate;
		this.host1Name = host1Name;
		this.host1Title = host1Title;
		this.host1ImageUrl = host1ImageUrl;
		this.host2Name = host2Name;
		this.host2Title = host2Title;
		this.host2ImageUrl = host2ImageUrl;
		this.host3Name = host3Name;
		this.host3Title = host3Title;
		this.host3ImageUrl = host3ImageUrl;
		this.isHidden = isHidden;
		this.parentId = parentId;
		this.entityTypeId = entityTypeId;
	}
}
