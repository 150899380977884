import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventLeaf } from 'src/app/models/_module';
import { DataService } from 'src/app/services/_modules';

@Component({
	selector: 'pm-event-view-list',
	templateUrl: './event-view-list.component.html',
	styleUrls: ['./event-view-list.component.scss'],
})
export class EventViewListComponent implements OnInit {
	@Input() item: EventLeaf;
	@Output() append: EventEmitter<EventLeaf> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	type = 'Event';
	dateStart: Date;
	dateEnd: Date;
	hasEnded: boolean;
	constructor(public dService: DataService) { }

	ngOnInit(): void {
		this.setDates();
	}

	setDates(): void {
		this.dateStart = this.dService.getTimeFromUtcDate(this.item.startTime);
		this.dateEnd = this.dService.getTimeFromUtcDate(this.item.endTime);
		this.hasEnded = this.dateEnd.getTime() < Date.now();
	}
	appendChild(item: EventLeaf) {
		this.append.emit(item);
		this.setDates();
	}

	deleteChild(id: number) {
		this.delete.emit(id);
	}

	getImageUrl(url: string): string {
		if (url == null || url === '') {
			return '../../../assets/imgs/no_photo.jpg';
		} else {
			return url;
		}
	}
}
