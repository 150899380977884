import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { ViewportRuler } from '@angular/cdk/overlay';
import { DragNDropComponent } from 'src/app/models/dragndropcomp';
import { Parent, ChildDisplayType, ParentPage } from 'src/app/models/_module';
import { DataService } from 'src/app/services/_modules';
import { IBreadcrumbs } from 'src/app/models/ibreadcrumbs';

@Component({
	templateUrl: './parent-page.component.html',
	styleUrls: ['./parent-page.component.scss'],
})
export class ParentPageComponent extends DragNDropComponent<Parent> implements OnInit, AfterViewInit {

	parentOfParents: Parent;
	backId = 0;
	title = '';
	header = '';
	visibility = '';
	resourceUrl = 'default.png';
	type = 'Folder';
	displayTypes: ChildDisplayType[] = [];
	errorMessage: string;
	crumbs: any;
	id: number;
	item: any;

	constructor(private dService: DataService, public router: Router,
		private route: ActivatedRoute, viewportRuler: ViewportRuler) {
		super(viewportRuler);
	}

	setupComponent(params: Params) {
		this.id = params['id'];
		this.dService.getParentPageById(this.id).subscribe(x => {
			this.crumbs = x.breadCrumbs;
			this.id = x.parent.id;
			this.parentOfParents = x.parent;
			this.title = x.parent.projectName;
			this.header = x.parent.title;
			this.backId = x.parent.parentId;
			this.dataList = x.children;
			this.visibility = x.parent.isHiddenDeep ? 'invisible' : 'visible';
		}, e => {
			const statusLevel = Math.floor(e.status / 100);
			if (statusLevel === 4) {
				alert('Session has expired.');
				this.dService.logout();
				this.router.navigate(['']);
			} else if (statusLevel === 5) {
				this.errorMessage = 'Server error. Please contact site admin.';
			}
		});
	}

	check(item) {
		this.item = item;
	}

	ngOnInit(): void {
		const { data, error }:
		{
			data:
			{
				breadCrumbs: [],
				parent: Parent,
				children: Parent[],
			},
			error: string,
		} = this.route.snapshot.data['page'];
		if (error) {
			this.errorMessage = error;
		} else {
			this.id = data.parent.id;
			this.parentOfParents = data.parent;
			this.title = data.parent.projectName;
			this.header = data.parent.title;
			this.backId = data.parent.parentId;
			this.dataList = data.children;
			const displayTypes = localStorage.getItem('displayData');
			this.displayTypes = JSON.parse(displayTypes);

			// subscription required to update the url and fetch appropriate data
			// when a folder is opened
			this.route.params.subscribe(params => {
				if (this.id !== params['id']) {
					this.setupComponent(params);
				}
			});
		}
	}

	ngAfterViewInit() {
		this.setPhElement();
	}

	appendPage(p: Parent) {
		if (p != null) {
			this.dataList.push(p);
		}
	}

	changeStyle() {
		this.changeStyleHelper();
		setTimeout(() => {
			const response = confirm('Would you like to save this change?');
			if (response) {
				this.parentOfParents.childDisplayTypeId = this.displayTypes.find(x => x.type === this.parentOfParents.childDisplayTypeName).id;
				this.dService.upsertParent(this.parentOfParents).subscribe(_ => {
					alert(`${this.header} has been updated`);
				});
			} else {
				this.changeStyleHelper();
			}
		}, 1200);
	}

	private changeStyleHelper() {
		if (this.parentOfParents.childDisplayTypeName === 'Grid') {
			this.parentOfParents.childDisplayTypeName = 'List';
		} else if (this.parentOfParents.childDisplayTypeName === 'List') {
			this.parentOfParents.childDisplayTypeName = 'Grid';
		}
	}

	deletePage(id: number) {
		this.dataList = this.dataList.filter(x => x.id !== id);
	}

	refreshPage() {
		location.reload();
	}

	actionMove(id: number, movingId: number, targetIndex: number) {
		this.dService.moveParentToIndex(id, movingId, targetIndex)
			.subscribe(x => {
				if (!x) {
					alert('Unable to save order changes right now. Try again later.');
					this.refreshPage();
				}
			});
	}
}
