import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { EventLeaf, Parent, BasicLeaf, ResourceLeaf, ContactLeaf, HtmlLeaf } from 'src/app/models/_module';
import { DataService } from 'src/app/services/_modules';

@Component({
	selector: 'pm-hidden-btn',
	templateUrl: './hidden-btn.component.html',
	styleUrls: ['./hidden-btn.component.scss'],
})
export class HiddenBtnComponent implements OnInit {

	@Input() item: any;
	@Input() type: string;
	// @ViewChild('eyeImg') eyeImg: ElementRef;
	// eyeBool: string;

	constructor(private dService: DataService) { }

	ngOnInit(): void {
		// if (this.item.isHidden === true) {
		// 	this.eyeBool = 'eye-c';
		// } else {
		// 	this.eyeBool = 'eye-o';
		// }
	}

	toggleIsHidden() {
		if (this.item.isHidden === true) {

			if (this.type === 'Folder') {
				if (this.item.parentId === 0 && this.item.imageId == null) {
					alert('This folder cannot be visible until it has an image.');
					return;
				}
				alert('This folder is now visible, but content within may still be hidden. Be sure to verify.');
			}

			// set to false
			this.item.isHidden = false;
			// change img
			// this.eyeBool = 'eye-o';
			// run switch
			this.updateIsHidden();
		} else {
			let result = true;

			if (this.type === 'Folder') {
				result = confirm('This folder and all content within will now be hidden. Are you sure you want to hide?');
			}

			if (result) {
				// set to true
				this.item.isHidden = true;
				// change img
				// this.eyeBool = 'eye-c';
				// run switch
				this.updateIsHidden();
			}
		}
	}

	showError() {
		alert('Error changing visibility');
	}

	updateIsHidden() {
		// update to use output emitter instead of switch
		switch (this.type) {
			case 'Folder':
				const childP: Parent = this.item;
				this.dService.upsertParent(childP).subscribe(_ => { }, _ => { this.showError(); });
				break;
			case 'Basic':
				const childB: BasicLeaf = this.item;
				this.dService.upsertBasicLeaf(childB).subscribe(_ => { }, _ => { this.showError(); });
				break;
			case 'Resource':
				const childR: ResourceLeaf = this.item;
				this.dService.upsertResourceLeaf(childR).subscribe(_ => { }, _ => { this.showError(); });
				break;
			case 'Contact':
				const childC: ContactLeaf = this.item;
				this.dService.upsertContactLeaf(childC).subscribe(_ => { }, _ => { this.showError(); });
				break;
			case 'Event':
				const childE: EventLeaf = this.item;
				this.dService.upsertEventLeaf(childE).subscribe(_ => { }, _ => { this.showError(); });
				break;
			case 'Html':
				const childH: HtmlLeaf = this.item;
				this.dService.upsertHtmlLeaf(childH).subscribe(_ => { }, _ => { this.showError(); });
				break;
		}
	}
}
