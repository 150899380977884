import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Parent } from 'src/app/models/parent';
import { DataService } from 'src/app/services/_modules';
import { title } from 'process';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() crumbs;
  @Input() header;
  @Input() id;
  @Input() item;
  type="Folder";
  isEdit= true;
  breadcrumbHeader: any;

  constructor() { }

  ngOnInit(): void { }

  title(event){
    this.breadcrumbHeader = event;
  }

}
