<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Change Password</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<form [formGroup]="newPasswordForm">
			<div class="form-group">
				<label for="newPassword1">New Password: </label>
				<input id="newPassword1" formControlName="newPassword1" class="form-control" type="password" (input)="pwOnChange($event)">
			</div>
			<div>
				<p>Password Requirements:  <fa-icon *ngIf="hasMin && hasCap && hasLow && hasNum && hasSym" class="fa-xs text-success" [icon]="faCheck"></fa-icon></p>
				<ul *ngIf="!hasMin || !hasCap || !hasLow || !hasNum || !hasSym">
					<li>8 character minimum
						<fa-icon class="fa-xs text-success" [ngClass]="{'text-danger': !hasMin}" [icon]="hasMin ? faCheck : faTimes"></fa-icon>
					</li>
					<li>At least 1 capital letter
						<fa-icon class="fa-xs text-success" [ngClass]="{'text-danger': !hasCap}" [icon]="hasCap ? faCheck : faTimes"></fa-icon>
					</li>
					<li>At least 1 lowercase letter
						<fa-icon class="fa-xs text-success" [ngClass]="{'text-danger': !hasLow}" [icon]="hasLow ? faCheck : faTimes"></fa-icon>
					</li>
					<li>At least 1 number
						<fa-icon class="fa-xs text-success" [ngClass]="{'text-danger': !hasNum}" [icon]="hasNum ? faCheck : faTimes"></fa-icon>
					</li>
					<li>At least 1 symbol ( !@#$%^&*?_~- )
						<fa-icon class="fa-xs text-success" [ngClass]="{'text-danger': !hasSym}" [icon]="hasSym ? faCheck : faTimes"></fa-icon>
					</li>
				</ul>
			</div>
			<div class="form-group">
				<label for="newPassword2">Confirm New Password: </label>
				<input id="newPassword2" formControlName="newPassword2" class="form-control" type="password">
			</div>
			<div>
				<p>Matching Password&nbsp;
					<fa-icon class="fa-xs text-success" [ngClass]="{'text-danger': newPassword2.invalid}" [icon]="(newPassword2.invalid) ? faTimes : faCheck"></fa-icon>
				</p>
			</div>
		</form>
	</div>

	<div class="modal-footer d-flex justify-content-between">
		<div>
			<button type="button" class="btn btn-dark"(click)="modal.dismiss()">Cancel</button>
		</div>
		<div>
			<button id="changeButton" class="btn btn-primary"  (click)="changePassword()" [disabled]="newPasswordForm.invalid">Change Password</button>
		</div>
	</div>
</ng-template>
<a id="newPasswordModal" class="nav-link text-white" (click)="open(content)">Change Password</a>
